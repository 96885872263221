import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { format } from "date-fns";
import { CalendarIcon, AlertCircle, X, Plus } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useSuspenseQuery, useMutation } from "@tanstack/react-query";
import { getCredentialsFieldsQueryOptions } from "@/ajax/queries";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import { queryClient } from "@/ajax/queryClient";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { SaveButton } from "../Button/SaveButton";
import { Button } from "@/components/ui/button";

const formSchema = z.object({
  issue_date: z.date(),
  expiration_date: z.date(),
  ecard_code: z.string().min(1, "eCard code is required").max(255),
  delete: z.boolean().optional(),
});

type FormValues = z.infer<typeof formSchema>;

export function ACLSForm({ onSave }: { onSave: () => void }) {
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { data: acls } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions(["acls"]),
  );

  const hasACLS = Boolean(acls.acls?.values[0]?.value);

  useEffect(() => {
    setIsVisible(hasACLS);
  }, [hasACLS]);

  const mutation = useMutation({
    mutationFn: saveCredentialsFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["credentialsFields"] });
      setSaveStatus("idle");
      setErrorMessage(null);
      onSave();
      if (isDeleting) {
        setIsVisible(false);
        setIsDeleting(false);
      }
    },
    onError: (error) => {
      setSaveStatus("error");
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to save changes",
      );
      setTimeout(() => setSaveStatus("idle"), 4000);
    },
  });

  const aclsValue = acls.acls?.values[0]?.value as {
    issue_date: string;
    expiration_date: string;
    ecard_code: string;
  };

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      issue_date: aclsValue?.issue_date
        ? new Date(aclsValue.issue_date)
        : new Date(),
      expiration_date: aclsValue?.expiration_date
        ? new Date(aclsValue.expiration_date)
        : new Date(),
      ecard_code: aclsValue?.ecard_code || "",
      delete: false,
    },
  });

  function onSubmit(data: FormValues) {
    setSaveStatus("saving");
    setErrorMessage(null);

    if (isVisible) {
      mutation.mutate({
        fieldValues: [
          {
            field_code: "acls",
            value: {
              issue_date: format(data.issue_date, "yyyy-MM-dd"),
              expiration_date: format(data.expiration_date, "yyyy-MM-dd"),
              ecard_code: data.ecard_code,
            },
            index: 1,
            delete: false,
          },
        ],
      });
    } else {
      mutation.mutate({
        fieldValues: [
          {
            field_code: "acls",
            value: null,
            index: 1,
            delete: true,
          },
        ],
      });
    }
  }

  let formFields;
  if (isVisible) {
    formFields = (
      <div>
        <div className="flex justify-end">
          <Button
            type="button"
            variant="outline"
            onClick={() => {
              setIsVisible(false);
            }}
            className="flex items-center gap-2"
          >
            <X className="h-4 w-4" />
            Remove ACLS Certification
          </Button>
        </div>

        <div className="space-y-4">
          <FormField
            control={form.control}
            name="issue_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Issue Date</FormLabel>
                <div className="relative">
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    customInput={
                      <Input
                        value={
                          field.value ? format(field.value, "MM-dd-yyyy") : ""
                        }
                      />
                    }
                    dateFormat="MM-dd-yyyy"
                    wrapperClassName="w-full"
                  />
                  <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="expiration_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Expiration Date</FormLabel>
                <div className="relative">
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    customInput={
                      <Input
                        value={
                          field.value ? format(field.value, "MM-dd-yyyy") : ""
                        }
                      />
                    }
                    dateFormat="MM-dd-yyyy"
                    wrapperClassName="w-full"
                  />
                  <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="ecard_code"
            render={({ field }) => (
              <FormItem>
                <FormLabel>eCard Code</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    );
  } else {
    formFields = (
      <Button
        type="button"
        variant="outline"
        onClick={() => setIsVisible(true)}
        className="flex items-center gap-2"
      >
        <Plus className="h-4 w-4" />
        Add ACLS Certification
      </Button>
    );
  }

  return (
    <Form {...form}>
      {errorMessage && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2">{errorMessage}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        {formFields}
        <SaveButton saveStatus={saveStatus} />
      </form>
    </Form>
  );
}
