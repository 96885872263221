import { createFileRoute } from "@tanstack/react-router";
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { BasicDetailsForm } from "@/components/Forms/BasicInformationForm";;
import { Button } from "@/components/ui/button";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getCredentialsFieldsQueryOptions } from "@/ajax/queries";
import { format, parse } from "date-fns";
import { AddressForm } from "@/components/Forms/AddressForm";
import { ProfileNavigation } from "@/features/profile/components/ProfileNavigation";

export const Route = createFileRoute(
  "/_authenticated/provider/profile/basic-info/",
)({
  component: ProfilePage,
});

function ProfilePage() {
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const { data: basicDetails } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions([
      "name",
      "npi_number",
      "other_names",
      "date_of_birth",
      "address",
    ]),
  );

  const name = basicDetails.name?.values[0].value as {
    first_name: string;
    middle_name: string;
    last_name: string;
    suffix_name: string;
  } | null;
  const address = basicDetails.address?.values[0].value as {
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    zip_code: string;
  } | null;

  return (
    <div className="p-2 md:p-8 space-y-4">
      <ProfileNavigation />
      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <div>
            <CardTitle className="text-2xl font-bold">
              Personal Information
            </CardTitle>
            <CardDescription>
              View and manage your profile information
            </CardDescription>
          </div>
          <Button variant="outline" onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {isEditing ? (
            <BasicDetailsForm onSave={() => setIsEditing(false)} />
          ) : (
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="text-sm text-gray-500">Full Name</div>
                  <div className="font-medium">
                    {name?.first_name || name?.last_name ? (
                      <>
                        {String(name?.first_name || "")}{" "}
                        {String(name?.middle_name || "")}{" "}
                        {String(name?.last_name || "")}{" "}
                        {String(name?.suffix_name || "")}
                      </>
                    ) : (
                      <span>Not provided</span>
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-500">NPI Number</div>
                  <div className="font-medium">
                    {String(
                      basicDetails.npi_number?.values[0].value ||
                        "Not provided",
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-500">Date of Birth</div>
                  <div className="font-medium">
                    {basicDetails.date_of_birth?.values[0].value
                      ? format(
                          parse(
                            String(basicDetails.date_of_birth.values[0].value),
                            "yyyy-MM-dd",
                            new Date(),
                          ),
                          "MMMM d, yyyy",
                        )
                      : "Not provided"}
                  </div>
                </div>
              </div>

              {basicDetails.other_names?.values.filter((val) => val.value)
                .length > 0 && (
                <div className="mt-6">
                  <div className="text-sm text-gray-500 mb-2">Other Names</div>
                  {basicDetails.other_names.values
                    .filter((val) => val.value)
                    .map((name: any, index: number) => (
                      <div key={index} className="font-medium">
                        {String(name.value.first_name)}{" "}
                        {String(name.value.middle_name)}{" "}
                        {String(name.value.last_name)}{" "}
                        {String(name.value.suffix_name)}
                      </div>
                    ))}
                </div>
              )}
            </div>
          )}
        </CardContent>
      </Card>

      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <div>
            <CardTitle className="text-2xl font-bold">Address</CardTitle>
            <CardDescription>View and manage your address</CardDescription>
          </div>
          <Button
            variant="outline"
            onClick={() => setIsEditingAddress(!isEditingAddress)}
          >
            {isEditingAddress ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {isEditingAddress ? (
            <AddressForm onSave={() => setIsEditingAddress(false)} />
          ) : (
            <div>
              {address ? (
                <div className="font-medium">
                  <div>{address.address_1}</div>
                  {address.address_2 && <div>{address.address_2}</div>}
                  <div>
                    {address.city}, {address.state} {address.zip_code}
                  </div>
                </div>
              ) : (
                <div className="font-medium">Not provided</div>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
