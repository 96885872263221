import { Bell, BellRing, CalendarRange, Settings } from "lucide-react";
import { Button } from "@/components/ui/button";
import { PushNotificationService } from "@/common/utils/PushNotificationService";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";
import {
  Dialog,
  DialogContent, 
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";

import { useState } from "react";

export function PushNotificationsForm({
  onComplete,
}: {
  onComplete: (success: boolean) => void;
}) {
  const [isBlocked, setIsBlocked] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleEnablePushNotifications = async () => {
    // Check if notifications are blocked
    const permissionStatus = await PushNotificationService.requestPermission();

    if (!permissionStatus) {
      setIsBlocked(true);
      return;
    }

    onComplete(true);
  };

  const openNotificationSettings = async () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  const notificationBenefits = (
    <>
      <p className="text-gray-600 mb-8">Get timely updates about opportunities that match your preferences</p>

      <ul className="space-y-4">
        <li className="flex items-center p-4 border rounded-lg">
          <BellRing className="h-5 w-5 text-gray-600 flex-shrink-0 mr-4" />
          <div>
            <span className="font-medium">Document Reminders</span>
            <p className="text-sm text-gray-500 mt-1">Never miss important credential renewals</p>
          </div>
        </li>
        <li className="flex items-center p-4 border rounded-lg">
          <CalendarRange className="h-5 w-5 text-gray-600 flex-shrink-0 mr-4" />
          <div>
            <span className="font-medium">Shift Alerts</span>
            <p className="text-sm text-gray-500 mt-1">First access to matching opportunities</p>
          </div>
        </li>
      </ul>

      <p className="text-sm text-gray-500 mt-6">
        Customize your notification preferences anytime
      </p>
    </>
  );

  return (
    <div>
      <div className="space-y-4 mb-8">
        {notificationBenefits}

        {isBlocked && (
          <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
            <div className="flex items-center mb-2">
              <Settings className="h-5 w-5 mr-2 text-yellow-600" />
              <p className="font-medium text-yellow-800">
                Notifications are blocked
              </p>
            </div>
            <p className="text-sm text-yellow-700 mb-4">
              Please enable notifications in your browser/device settings to
              receive important updates.
            </p>
            <Button
              variant="outline"
              size="lg"
              onClick={openNotificationSettings}
              className="w-full"
            >
              Open Notification Settings
            </Button>
          </div>
        )}
      </div>

      <div className="space-y-3">
        <Button
          size="lg"
          className="w-full py-6 text-lg transition-all duration-200 group"
          onClick={handleEnablePushNotifications}
        >
          <Bell className="mr-2 h-5 w-5 group-hover:scale-110 transition-transform" />
          Enable Notifications
        </Button>

        <div className="flex justify-center">
          <Button
            variant="ghost"
            className="text-gray-500"
            onClick={() => setShowConfirmDialog(true)}
          >
            Maybe Later
          </Button>
        </div>
      </div>

      <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <DialogContent className="sm:max-w-[425px] w-[95vw] max-h-[95vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-xl">Don't Miss Out</DialogTitle>
            <DialogDescription className="pt-3">
              Without notifications enabled, you might miss:
              <ul className="mt-4 space-y-4 list-none">
                <li className="flex items-center gap-3">
                  <BellRing className="h-5 w-5 text-gray-600 flex-shrink-0" />
                  <span>Time-sensitive document alerts</span>
                </li>
                <li className="flex items-center gap-3">
                  <CalendarRange className="h-5 w-5 text-gray-600 flex-shrink-0" />
                  <span>Matching shift opportunities</span>
                </li>
              </ul>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex flex-col gap-3 sm:flex-row sm:gap-2">
            <Button
              variant="default"
              className="w-full sm:w-auto"
              onClick={handleEnablePushNotifications}
            >
              Enable Notifications
            </Button>
            <Button 
              variant="outline"
              className="w-full sm:w-auto"
              onClick={() => {
                setShowConfirmDialog(false);
                onComplete(false);
              }}
            >
              No Thanks
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
