import {
  PushNotifications,
  PermissionStatus,
} from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";

export class PushNotificationService {
  // Check if we're running on a native platform that supports push
  private static isPushAvailable(): boolean {
    return (
      Capacitor.isNativePlatform() &&
      (Capacitor.getPlatform() === "ios" ||
        Capacitor.getPlatform() === "android")
    );
  }

  // Request permission safely
  static async requestPermission(): Promise<boolean> {
    if (!this.isPushAvailable()) {
      console.error("Push notifications are not available on this platform");
      throw new Error("Push notifications are not available on this platform");
    }

    const currentPermissions = await PushNotifications.checkPermissions();

    if (currentPermissions.receive === "prompt") {
      const permissionStatus = await PushNotifications.requestPermissions();
        if (permissionStatus.receive === "granted") {
          await PushNotifications.register();
          return true;
        }
    } else if (currentPermissions.receive === "granted") {
      await PushNotifications.register();
      return true;
    }

    return false;
  }

  private static setupPushListeners(): void {
    // Registration success
    PushNotifications.addListener("registration", (token) => {
      console.log("Push registration success:", token.value);
      // Send token to your server here
    });

    // Registration error
    PushNotifications.addListener("registrationError", (error) => {
      console.error("Push registration error:", error);
    });

    // Push received
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push received:", notification);
      },
    );

    // Push action performed
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (action) => {
        console.log("Push action performed:", action);
      },
    );
  }

  // Public method to check current permission status
  static async checkPermissionStatus(): Promise<PermissionStatus | null> {
    if (!this.isPushAvailable()) {
      return null;
    }
    return await PushNotifications.checkPermissions();
  }
}
