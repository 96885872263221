import NotificationSettingsForm from "@/components/settings/NotificationSettingsForm";
import { createFileRoute } from "@tanstack/react-router";
import { queryClient } from "../../../../ajax/queryClient";
import { communicationPreferencesQueryOptions } from "../../../../ajax/queries";

export const Route = createFileRoute("/_authenticated/provider/settings/")({
  component: SettingsPage,
  loader: () =>
    queryClient.ensureQueryData(communicationPreferencesQueryOptions()),
});

export default function SettingsPage() {
  return (
    <div className="p-2 pt-4 md:pt-8 md:p-8">
      <h1 className="text-2xl font-bold mb-6">Account Settings</h1>
      <NotificationSettingsForm />
    </div>
  );
}
