import { createFileRoute } from "@tanstack/react-router";
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ReferencesForm } from "@/components/Forms/ReferencesForm";
import { Button } from "@/components/ui/button";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getCredentialsFieldsQueryOptions } from "@/ajax/queries";
import { ProfileNavigation } from "@/features/profile/components/ProfileNavigation";

export const Route = createFileRoute(
  "/_authenticated/provider/profile/references/",
)({
  component: ReferencesPage,
});

function ReferencesPage() {
  const [showForm, setShowForm] = useState(false);
  const {
    data: { professional_references },
  } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions(["professional_references"]),
  );

  const references = professional_references?.values
    .filter((val) => val.value)
    .map(
      (val) =>
        val.value as {
          first_name: string;
          last_name: string;
          relationship_description: string;
          email: string;
          phone_number: string;
        },
    );

  const formatPhoneNumber = (phone: string) => {
    // Remove +1 prefix if present and any non-digit characters
    const cleaned = phone.replace(/^\+1/, "").replace(/\D/g, "");
    // Format as (XXX) XXX-XXXX
    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  return (
    <div className="p-2 md:p-8 space-y-4">
      <ProfileNavigation />
      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <div>
            <CardTitle className="text-2xl font-bold">
              Professional References
            </CardTitle>
            <CardDescription>
              View and manage your professional references
            </CardDescription>
          </div>
          <Button variant="outline" onClick={() => setShowForm(!showForm)}>
            {showForm ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {showForm ? (
            <ReferencesForm onSave={() => setShowForm(false)} />
          ) : (
            <div className="space-y-6">
              {references && references.length > 0 ? (
                references.map((reference, index) => (
                  <div key={index} className="space-y-2">
                    <div className="flex items-center gap-2">
                      <h4 className="font-semibold text-lg">
                        {reference.first_name} {reference.last_name}
                      </h4>
                    </div>
                    <div className="space-y-1">
                      <div className="text-sm text-gray-500">
                        {reference.relationship_description}
                      </div>
                      <div className="text-sm text-gray-500">
                        Email: {reference.email}
                      </div>
                      <div className="text-sm text-gray-500">
                        Phone: {formatPhoneNumber(reference.phone_number)}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-500">
                  No references added yet. Click Edit to add your references.
                </div>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
