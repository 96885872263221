import { differenceInDays, format, parse } from "date-fns";
import { Badge, BadgeProps } from "../ui/badge";
export const ExpirationBadge = ({ expiresAt }: { expiresAt: string }) => {
  const expiresAtDate = parse(expiresAt, "yyyy-MM-dd", new Date());
  const now = new Date();
  const daysUntilExpiry = differenceInDays(expiresAtDate, now);

  let badgeColor: BadgeProps["variant"] = "outline";
  let size: BadgeProps["size"] = "light";
  if (daysUntilExpiry <= 14) {
    badgeColor = "destructive";
    size = "default";
  } else if (daysUntilExpiry <= 60) {
    badgeColor = "warning";
    size = "default";
  }

  return (
    <Badge variant={badgeColor} size={size}>
      {format(expiresAtDate, "MMM d, yyyy")}
    </Badge>
  );
};
