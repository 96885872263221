import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { X, AlertCircle, CalendarIcon } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useSuspenseQuery, useMutation } from "@tanstack/react-query";
import { getCredentialsFieldsQueryOptions } from "@/ajax/queries";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import { queryClient } from "@/ajax/queryClient";
import { useState } from "react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { SaveButton } from "../Button/SaveButton";
import { USStateSelect } from "../Selects/USStateSelect";
import { SpecialtiesSelect } from "../Selects/SpecialtiesSelect";

const specialtySchema = z.object({
  specialty_code: z.string().min(1, "Specialty code is required").max(255),
  certifying_board: z.string().min(1, "Certifying board is required").max(255),
  state: z.string().max(2).optional(),
  certification_number: z
    .string()
    .min(1, "Certification number is required")
    .max(255),
  initial_certification_date: z.date(),
  expiration_date: z.date(),
  deleted: z.boolean().optional(),
});

const formSchema = z.object({
  specialties: z.array(specialtySchema),
});

type FormValues = z.infer<typeof formSchema>;

export function SpecialtiesForm({ onSave }: { onSave: () => void }) {
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data: specialties } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions(["specialty"]),
  );

  const mutation = useMutation({
    mutationFn: saveCredentialsFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["credentialsFields"] });
      setSaveStatus("idle");
      setErrorMessage(null);
      onSave();
    },
    onError: (error) => {
      setSaveStatus("error");
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to save changes",
      );
      setTimeout(() => setSaveStatus("idle"), 4000);
    },
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      specialties:
        specialties.specialty?.values
          .filter((val) => val.value)
          .map((val: { value: any }) => ({
            specialty_code: val.value?.specialty_code || "",
            certifying_board: val.value?.certifying_board || "",
            state: val.value?.state || "",
            certification_number: val.value?.certification_number || "",
            initial_certification_date: val.value?.initial_certification_date
              ? new Date(val.value.initial_certification_date)
              : new Date(),
            expiration_date: val.value?.expiration_date
              ? new Date(val.value.expiration_date)
              : new Date(),
          })) || [],
    },
  });

  const {
    fields: specialtyFields,
    append: appendSpecialty,
    remove: removeSpecialty,
  } = useFieldArray({
    control: form.control,
    name: "specialties",
  });

  function onSubmit(data: FormValues) {
    setSaveStatus("saving");
    setErrorMessage(null);

    const existingValues = specialties.specialty?.values || [];
    const newIndexMax = data.specialties.length;

    const fieldValues = [
      ...data.specialties.map((specialty, index) => ({
        field_code: "specialty",
        value: {
          specialty_code: specialty.specialty_code,
          certifying_board: specialty.certifying_board,
          state: specialty.state,
          certification_number: specialty.certification_number,
          initial_certification_date: format(
            specialty.initial_certification_date,
            "yyyy-MM-dd",
          ),
          expiration_date: format(specialty.expiration_date, "yyyy-MM-dd"),
        },
        index: index + 1,
        delete: false,
      })),

      ...existingValues
        .filter((val: { index: number }) => val.index > newIndexMax)
        .map((val: { index: number }) => ({
          field_code: "specialty",
          value: null,
          delete: true,
          index: val.index,
        })),
    ];

    mutation.mutate({
      fieldValues,
    });
  }

  const renderSpecialtyFields = (fields: any[], remove: any) => {
    return fields.map((field, index) => (
      <div key={field.id} className="space-y-4 p-4 border rounded-lg relative">
        {index > 0 && (
          <Button
            type="button"
            variant="ghost"
            size="icon"
            className="absolute right-2 top-2"
            onClick={() => remove(index)}
          >
            <X className="h-4 w-4" />
          </Button>
        )}

        <FormField
          control={form.control}
          name={`specialties.${index}.specialty_code`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {index === 0 ? "Primary Specialty" : "Specialty"}
              </FormLabel>
              <FormControl>
                <SpecialtiesSelect
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`specialties.${index}.certifying_board`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Certifying Board</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`specialties.${index}.state`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>State</FormLabel>
              <FormControl>
                <USStateSelect
                  value={field.value}
                  onChange={(value: string) => field.onChange(value)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`specialties.${index}.certification_number`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Certification Number</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name={`specialties.${index}.initial_certification_date`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Initial Certification Date</FormLabel>
                <div className="relative">
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    customInput={
                      <Input
                        value={
                          field.value ? format(field.value, "MM-dd-yyyy") : ""
                        }
                      />
                    }
                    dateFormat="MM-dd-yyyy"
                    wrapperClassName="w-full"
                  />
                  <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={`specialties.${index}.expiration_date`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Expiration Date</FormLabel>
                <div className="relative">
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    customInput={
                      <Input
                        value={
                          field.value ? format(field.value, "MM-dd-yyyy") : ""
                        }
                      />
                    }
                    dateFormat="MM-dd-yyyy"
                    wrapperClassName="w-full"
                  />
                  <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    ));
  };

  return (
    <Form {...form}>
      {errorMessage && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2">{errorMessage}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-6">
          <div>
            <div className="space-y-4">
              {renderSpecialtyFields(specialtyFields, removeSpecialty)}
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={() =>
                  appendSpecialty({
                    specialty_code: "",
                    certifying_board: "",
                    state: "",
                    certification_number: "",
                    initial_certification_date: new Date(),
                    expiration_date: new Date(),
                  })
                }
              >
                Add Specialty
              </Button>
            </div>
          </div>
        </div>

        <SaveButton saveStatus={saveStatus} />
      </form>
    </Form>
  );
}
