import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { createFileRoute, Link, redirect } from "@tanstack/react-router";
import { AlertCircle } from "lucide-react";
import { filesQueryOptions } from "@/ajax/queries";
import { DOCUMENT_CATEGORIES } from "@/consts/document_categories";
import { queryClient } from "@/ajax/queryClient";

export const Route = createFileRoute("/_authenticated/provider/profile/")({
  beforeLoad: async ({ context }) => {
    const filesQuery = await queryClient.ensureQueryData(filesQueryOptions());
    const missingDocuments = DOCUMENT_CATEGORIES.filter((document) => {
      return (
        !document.optional &&
        !filesQuery.some((f) => document.fileTypeIds.includes(f.type_id))
      );
    });

    if (missingDocuments.length === 0) {
      throw redirect({
        to: "/provider/profile/basic-info",
      });
    }
  },
  component: ProfilePage,
});

function ProfilePage() {
  return (
    <div className="min-h-screen bg-gray-100 p-2 md:p-8">
      <Card className="max-w-2xl mx-auto w-full">
        <CardHeader>
          <CardTitle className="text-xl md:text-2xl font-bold">
            Finish Uploading Your Documents
          </CardTitle>
          <CardDescription>
            Uploading all your documents will automatically fill out most of
            your profile information
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <Alert>
            <AlertCircle className="h-6 w-6" />
            <AlertDescription className="ml-2">
              We recommend uploading all your documents as it will save you time
              by automatically filling out most of your profile information. You
              can continue and enter your information manually.
            </AlertDescription>
          </Alert>
          <div className="flex flex-col sm:flex-row gap-4">
            <Link to="/provider/documents" className="flex-1">
              <Button className="w-full">Upload Documents</Button>
            </Link>
            <Link to="/provider/profile/basic-info">
              <Button variant="outline" className="w-full">
                Continue Without Documents
              </Button>
            </Link>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
