import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import React, { useState } from "react";
import "react-international-phone/style.css";
import Loading from "@/components/Loading";
import { getHealthcareRoles } from "@/ajax/shift_preferences/getHealthcareRoles";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useSuspenseQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { updateOnboardingAccountDetails } from "@/ajax/onboarding/updateOnboardingAccountDetails";
import { CREDENTIALS_FIELDS_QUERY_KEY, getCredentialsFieldsQueryOptions, PROVIDER_USER_QUERY_KEY } from "@/ajax/queries";

type Inputs = {
  firstName: string;
  lastName: string;
  npiNumber: string;
  acceptTerms: boolean;
  healthcareProfessionalType: string;
};

type CredentialsField = {
  code: string;
  value: any;
};

export default function AccountDetailsOnboardingForm({
  onComplete,
}: {
  onComplete: () => void;
}) {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { data: healthcareRoles } = useSuspenseQuery({
    queryKey: ["healthcareRoles"],
    queryFn: getHealthcareRoles,
  });

  const { data: credentialsFields } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions(["name", "npi_number", "healthcare_role"]),
  );

  const healthcareRolesData = healthcareRoles.map((role) => ({
    value: role.id,
    label: role.name,
  }));

  const name = credentialsFields.name.values[0].value as { first_name: string, last_name: string };
  const npiNumber = credentialsFields.npi_number.values[0].value as string;
  const healthcareRole = credentialsFields.healthcare_role.values[0].value as { healthcare_role_id: string };

  const form = useForm<Inputs>({
    defaultValues: {
      firstName: name?.first_name || "",
      lastName: name?.last_name || "",
      npiNumber: npiNumber || "",
      healthcareProfessionalType: healthcareRole?.healthcare_role_id || "",
      acceptTerms: false,
    },
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitted },
  } = form;

  const { mutateAsync: updateAccountDetails } = useMutation({
    mutationFn: updateOnboardingAccountDetails,
    onSuccess: () => {
      setSubmitError(null);
      queryClient.invalidateQueries({ queryKey: [CREDENTIALS_FIELDS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [PROVIDER_USER_QUERY_KEY] });
      onComplete();
    },
    onError: () => {
      setSubmitError("An error occurred. Please try again.");
    },
  });

  const onSubmit = async (data: Inputs) => {
    await updateAccountDetails({
      firstName: data.firstName,
      lastName: data.lastName,
      npiNumber: data.npiNumber,
      healthcareProfessionalTypeId: data.healthcareProfessionalType,
    });
  };

  return (
    <Form {...form}>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4.5">
          <FormField
            rules={{
              required: "First name is required",
            }}
            name="firstName"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  First name <span className="text-meta-1">*</span>
                </FormLabel>
                <FormControl>
                  <Input
                    id="first_name_input"
                    type="text"
                    placeholder="Enter your first name"
                    autoFocus={true}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="mb-4.5">
          <FormField
            rules={{
              required: "Last name is required",
            }}
            name="lastName"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Last name <span className="text-meta-1">*</span>
                </FormLabel>
                <FormControl>
                  <Input
                    id="last_name_input"
                    type="text"
                    placeholder="Enter your last name"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="mb-4.5 space-y-1">
          <FormField
            name="healthcareProfessionalType"
            rules={{
              required: "Healthcare role is required",
            }}
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Select Healthcare Role <span className="text-meta-1">*</span>
                </FormLabel>
                <FormControl>
                  <Select
                    placeholder="Select Healthcare Role"
                    options={healthcareRolesData}
                    {...field}
                    classNames={{
                      control: (state) => "py-2",
                    }}
                    value={healthcareRolesData.find(
                      (option) => option.value === field.value,
                    )}
                    onChange={(
                      selectedOption: { value: string; label: string } | null,
                    ) => {
                      field.onChange(selectedOption?.value ?? "");
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="mb-4.5">
          <FormField
            rules={{
              required: "NPI number is required",
              pattern: {
                value: /^\d{10}$/,
                message: "Invalid NPI number. Must be 10 digits",
              },
            }}
            name="npiNumber"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  NPI Number <span className="text-meta-1">*</span>
                </FormLabel>
                <FormControl>
                  <Input
                    maxLength={10}
                    id="npi_number_input"
                    type="text"
                    placeholder="Enter NPI Number"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="mb-5">
          <Controller
            name="acceptTerms"
            control={control}
            rules={{
              required:
                "You must accept the terms of service and privacy policy",
            }}
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                <FormControl>
                  <Checkbox
                    {...field}
                    value="Accept"
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    id="acceptTerms"
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    Agree to our{" "}
                    <a href="/terms" className="text-blue-600 hover:underline">
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacy"
                      className="text-blue-600 hover:underline"
                    >
                      Privacy Policy
                    </a>
                    .
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
          {errors.acceptTerms && (
            <p className="text-red-500 text-sm mt-1">
              {errors.acceptTerms.message}
            </p>
          )}
        </div>
        {submitError && <p className="text-red-500 my-2">{submitError}</p>}
        <div className="mb-5">
          <Button size="none" className="w-full py-4" type="submit">
            {isSubmitting ? <Loading className="w-4 h-4" /> : "Save"}
          </Button>
        </div>
      </form>
    </Form>
  );
}
