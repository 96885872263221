import { queryOptions } from "@tanstack/react-query";
import { getRenewals } from "./renewals/getRenewals";
import { getProviderUser } from "./auth/getProviderUser";
import { getFiles } from "./documents/getFiles";
import { getProviderCommunicationPreferences } from "./settings/getProviderCommunicationPreferences";
import { getFileTypes } from "./documents/getFileTypes";
import { getReferrals } from "./referrals/getReferrals";
import { createReferralLink } from "./referrals/createReferralLink";
import { getHealthcareRoles } from "./shift_preferences/getHealthcareRoles";
import { getSpecialties } from "./shift_preferences/getSpecialties";
import { getCredentialsFields } from "./credentials/getCredentialsFields";
import { getSpecialtyCodes } from "./shift_preferences/getSpecialtyCodes";

export const FILES_QUERY_KEY = "files";
export const filesQueryOptions = ({
  switchUserId,
  id,
}: { switchUserId?: string; id?: string } = {}) =>
  queryOptions({
    queryKey: [FILES_QUERY_KEY, switchUserId, id],
    queryFn: () =>
      getFiles({
        id,
        switchUserId,
      }),
  });

export const PROVIDER_USER_QUERY_KEY = "providerUser";
export const providerUserQueryOptions = () =>
  queryOptions({
    queryKey: [PROVIDER_USER_QUERY_KEY],
    queryFn: getProviderUser,
  });

export const RENEWALS_QUERY_KEY = "renewals";
export const renewalsQueryOptions = ({
  switchUserId,
  fileId,
}: {
  switchUserId?: string;
  fileId?: string;
} = {}) =>
  queryOptions({
    queryKey: [RENEWALS_QUERY_KEY, switchUserId, fileId],
    queryFn: () =>
      getRenewals({
        switchUserId,
        fileId,
      }),
  });

export const COMMUNICATION_PREFERENCES_QUERY_KEY = "communicationPreferences";
export const communicationPreferencesQueryOptions = () =>
  queryOptions({
    queryKey: [COMMUNICATION_PREFERENCES_QUERY_KEY],
    queryFn: () => getProviderCommunicationPreferences(),
  });

export const FILE_TYPES_QUERY_KEY = "userCredentialsFileTypes";
export const fileTypesQueryOptions = () =>
  queryOptions({
    queryKey: [FILE_TYPES_QUERY_KEY],
    queryFn: () => getFileTypes(),
  });

export const REFERRALS_QUERY_KEY = "referrals";
export const referralsQueryOptions = () =>
  queryOptions({
    queryKey: [REFERRALS_QUERY_KEY],
    queryFn: () => getReferrals(),
  });

export const CREATE_REFERRAL_LINK_QUERY_KEY = "createReferralLink";
export const createReferralLinkQueryOptions = () =>
  queryOptions({
    queryKey: [CREATE_REFERRAL_LINK_QUERY_KEY],
    queryFn: () => createReferralLink(),
  });

export const SPECIALTIES_QUERY_KEY = "specialties";
export const getSpecialtiesQueryOptions = () =>
  queryOptions({
    queryKey: [SPECIALTIES_QUERY_KEY],
    queryFn: () => getSpecialties(),
  });

export const SPECIALTY_CODES_QUERY_KEY = "specialty";
export const getSpecialtyCodesQueryOptions = (codes: string[]) =>
  queryOptions({
    queryKey: [SPECIALTY_CODES_QUERY_KEY, codes],
    queryFn: () => getSpecialtyCodes(codes),
  });

export const HEALTHCARE_ROLES_QUERY_KEY = "healthcareRoles";
export const getHealthcareRolesQueryOptions = () =>
  queryOptions({
    queryKey: [HEALTHCARE_ROLES_QUERY_KEY],
    queryFn: getHealthcareRoles,
  });

export const CREDENTIALS_FIELDS_QUERY_KEY = "credentialsFields";
export const getCredentialsFieldsQueryOptions = <T extends string>(
  fieldCodes: T[],
) =>
  queryOptions({
    queryKey: [CREDENTIALS_FIELDS_QUERY_KEY, fieldCodes],
    queryFn: () => getCredentialsFields({ fieldCodes }),
    staleTime: 1000,
  });
