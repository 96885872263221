"use client";
import React from "react";
import OnboardingContainer from "@/features/onboarding/components/OnboardingContainer";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/provider-onboarding/")({
  component: ProviderOnboardingPage,
});

function ProviderOnboardingPage() {
  return <OnboardingContainer />;
}
