import { Button } from "@/components/ui/button";
import Loading from "../Loading";
import { cn } from "@/lib/utils";

interface SaveButtonProps {
  saveStatus: "idle" | "saving" | "saved" | "error";
  className?: string;
}

export function SaveButton({ saveStatus, className }: SaveButtonProps) {
  return (
    <Button
      type="submit"
      className={cn(
        "w-full py-6",
        saveStatus === "saved" && "bg-green-600 hover:bg-green-700",
        saveStatus === "error" && "bg-red-600 hover:bg-red-700",
        className,
      )}
      disabled={saveStatus === "saving"}
    >
      {saveStatus === "saving" ? (
        <Loading className="w-6 h-6" />
      ) : saveStatus === "saved" ? (
        "Saved!"
      ) : saveStatus === "error" ? (
        "Failed to Save"
      ) : (
        "Save"
      )}
    </Button>
  );
}
