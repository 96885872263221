import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

export type Specialty = {
  id: string;
  code: string;
  grouping: string;
  classification: string;
  name: string;
  is_parent: boolean;
};
export const getSpecialties = async () => {
  return callSupabaseFnFromClient<Specialty[]>({
    fnName: "get_medical_specialties",
  });
};
