import { getEnvVar } from "@/common/utils/environment";
import { createClient } from "@supabase/supabase-js";

export const STORAGE_KEY = "sb.auth.token";

const createSupabaseClient = () => {
  return createClient(
    getEnvVar("VITE_PUBLIC_SUPABASE_URL"),
    getEnvVar("VITE_PUBLIC_SUPABASE_ANON_KEY"),
    {
      auth: {
        storageKey: STORAGE_KEY,
      },
    },
  );
};

export const supabase = createSupabaseClient();

export const getAccessToken = (): string | null => {
  const token = JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}");

  return token.access_token ?? null;
};
