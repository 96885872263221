import { Edit, HelpCircle, File } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { ExpirationBadge } from "../Badge/ExpirationBadge";

interface DocumentFile {
  id: string;
  file_name: string;
  type_id: number | null;
  generated_title: string | null;
  expires_at: string | null;
  renewals?: Array<{
    expires_at: string;
  }>;
}

interface DocumentListItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  files?: DocumentFile[];
  onEditFile: (fileId: string) => void;
  showRequiredMessage?: boolean;
  showGeneratedTitle?: boolean;
}

export const DocumentListItem = ({
  icon,
  title,
  description,
  files,
  onEditFile,
  showRequiredMessage,
  showGeneratedTitle = true,
}: DocumentListItemProps) => {
  return (
    <li>
      <div className="flex items-center">
        {icon}
        <span className="text-gray-600">{title}</span>
        <Popover>
          <PopoverTrigger className="ml-1">
            <HelpCircle className="h-4 w-4 text-gray-400 hover:text-gray-600" />
          </PopoverTrigger>
          <PopoverContent side="right" className="max-w-xs text-sm">
            <p>{description}</p>
            {showRequiredMessage && (
              <p className="mt-2 text-red-500">
                Required - Please upload this document using the upload area
                above
              </p>
            )}
          </PopoverContent>
        </Popover>
      </div>
      {files && files.length > 0 && (
        <ul className="ml-6 mt-1 space-y-1">
          {files.map((file, fileIndex) => (
            <li
              key={fileIndex}
              className="flex items-start justify-between cursor-pointer"
              onClick={() => onEditFile(file.id)}
              tabIndex={0}
              role="button"
              aria-label={`Edit ${file.file_name}`}
            >
              <div className="flex items-start">
                <File className="mr-1 h-4 w-4 mt-1" />
                <div className="flex flex-col">
                  <span>
                    {showGeneratedTitle && file.type_id && file.generated_title
                      ? file.generated_title
                      : file.file_name}
                  </span>
                  {showGeneratedTitle &&
                    file.type_id &&
                    file.generated_title && (
                      <div className="flex items-center gap-2 text-sm text-gray-500">
                        <span>{file.file_name}</span>
                        {file.expires_at &&
                          (() => {
                            return (
                              <ExpirationBadge expiresAt={file.expires_at} />
                            );
                          })()}
                      </div>
                    )}
                </div>
              </div>
              <Edit className="h-4 w-4" />
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
