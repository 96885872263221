import { Link } from "@tanstack/react-router";
import { useRouterState } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";

const profileRoutes = [
  {
    label: "Personal Information",
    route: "/provider/profile/basic-info",
  },
  {
    label: "Contact Information", 
    route: "/provider/profile/contact-info",
  },
  {
    label: "Specialties",
    route: "/provider/profile/specialties", 
  },
  {
    label: "Professional Licenses",
    route: "/provider/profile/professional-licenses",
  },
  {
    label: "Work Experience",
    route: "/provider/profile/work-experience",
  },
  {
    label: "Education & Training",
    route: "/provider/profile/education-training",
  },
  {
    label: "References",
    route: "/provider/profile/references",
  }
];

export function ProfileNavigation() {
  const router = useRouterState();
  const currentRoute = profileRoutes.find(
    route => route.route === router.location.pathname
  );

  return (
    <div className="md:hidden">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size="lg" variant="outline" className="w-full justify-between">
            {currentRoute?.label || "Select Section"}
            <ChevronDown className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-full min-w-[240px]">
          {profileRoutes.map((route) => (
            <DropdownMenuItem key={route.route} asChild>
              <Link
                to={route.route}
                className={router.location.pathname === route.route ? "bg-muted" : ""}
              >
                {route.label}
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

