import { createFileRoute } from "@tanstack/react-router";
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { SpecialtiesForm } from "@/components/Forms/SpecialtiesForm";
import { Button } from "@/components/ui/button";
import { useSuspenseQuery } from "@tanstack/react-query";
import {
  getCredentialsFieldsQueryOptions,
  getSpecialtyCodesQueryOptions,
} from "@/ajax/queries";
import { format } from "date-fns";
import { ProfileNavigation } from "@/features/profile/components/ProfileNavigation";

export const Route = createFileRoute(
  "/_authenticated/provider/profile/specialties/",
)({
  component: SpecialtiesPage,
});

function SpecialtiesPage() {
  const [showForm, setShowForm] = useState(false);
  const {
    data: { specialty },
  } = useSuspenseQuery(getCredentialsFieldsQueryOptions(["specialty"]));

  const specialties = specialty?.values
    .filter((val) => val.value)
    .map(
      (val) =>
        val.value as {
          specialty_code: string;
          initial_certification_date: string;
          expiration_date: string;
          certifying_board: string;
          state: string;
          certification_number: string;
        },
    );

  const specialtyCodes = specialties?.map((s) => s.specialty_code) || [];

  const { data: specialtyData } = useSuspenseQuery(
    getSpecialtyCodesQueryOptions(specialtyCodes),
  );

  const getSpecialtyName = (code: string) => {
    return specialtyData?.find((s) => s.code === code)?.name || code;
  };

  return (
    <div className="min-h-screen bg-gray-100 p-2 md:p-8 space-y-4">
      <ProfileNavigation />
      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <div>
            <CardTitle className="text-2xl font-bold">Specialties</CardTitle>
            <CardDescription>
              View and manage your specialty certifications
            </CardDescription>
          </div>
          <Button variant="outline" onClick={() => setShowForm(!showForm)}>
            {showForm ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {showForm ? (
            <SpecialtiesForm onSave={() => setShowForm(false)} />
          ) : (
            <div className="space-y-6">
              {specialties && specialties.length > 0 ? (
                specialties.map((specialty, index) => (
                  <div key={specialty.specialty_code}>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <h4 className="font-semibold text-lg">
                          {getSpecialtyName(specialty.specialty_code)}
                        </h4>
                        {index === 0 && (
                          <span className="text-sm text-gray-500">
                            (Primary)
                          </span>
                        )}
                      </div>
                      <div className="space-y-1">
                        <div className="font-medium">
                          {specialty.certifying_board}
                        </div>
                        <div className="text-sm text-gray-500">
                          Issued:{" "}
                          {format(
                            new Date(specialty.initial_certification_date),
                            "MMM yyyy",
                          )}
                        </div>
                        <div className="text-sm text-gray-500">
                          Expires:{" "}
                          {format(
                            new Date(specialty.expiration_date),
                            "MMM yyyy",
                          )}
                        </div>
                        <div className="text-sm text-gray-500">
                          Number: {specialty.certification_number}
                        </div>
                        {specialty.state && (
                          <div className="text-sm text-gray-500">
                            State: {specialty.state}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-500 border rounded-lg p-4">
                  No specialties added yet. Click Edit to add your specialties.
                </div>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
