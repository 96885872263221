import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";
import { Json } from "../../common/types/database.types";
export type FieldValueType = {
  id: number;
  code: string;
  display_name: string;
  schema: Json;
  values: {
    value: Json;
    index: number;
    source_id: string | null;
    source_title: string | null;
  }[];
  source_id: string;
  source_title: string;
};

export const getCredentialsFields = async <T extends string>({
  fieldCodes,
  switchUserId,
}: {
  fieldCodes: T[];
  switchUserId?: string | undefined;
}): Promise<Record<T, FieldValueType>> => {
  const response = await callSupabaseFnFromClient<FieldValueType[]>({
    fnName: "get_user_credentials_fields",
    args: {
      field_codes: fieldCodes,
      switch_user_id: switchUserId,
    },
  });

  return response.reduce(
    (acc, field) => {
      acc[field.code] = field;
      return acc;
    },
    {} as Record<string, FieldValueType>,
  );
};
