import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import React from "react";
import { useProviderUser } from "@/hooks/useProviderUser";
import { User } from "lucide-react";
import { show } from "@intercom/messenger-js-sdk";
import { supabase } from "@/ajax/clients/supabase";
import { Link } from "@tanstack/react-router";

export const UserAvatar = () => {
  const providerUser = useProviderUser();

  const onSignOutClick = async () => {
    const { error } = await supabase.auth.signOut();
    document.location.reload();
  };

  const onSupportClick = async () => {
    show();
  };

  const name = providerUser.credentials.find((credential) => credential.code === "name")?.value as { first_name: string; last_name: string };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="secondary"
          size="icon"
          className="rounded-full bg-[#10348e] hover:bg-[#10348e]/90"
        >
          {name?.first_name && name?.last_name ? (<svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
            className="w-full h-full"
          >
            <text
              x="100"
              y="125"
              fontFamily="Arial, sans-serif"
              fontSize="80"
              textAnchor="middle"
              fill="#ffffff"
            >
               {`${name?.first_name[0]}${name?.last_name[0]}`}
              </text>
            </svg>
          ) : (
            <User className="w-5 h-5" color="white" />
          )}
          <span className="sr-only">Toggle user menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link to="/provider/profile" className="w-full">
            Profile
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link to="/provider/settings" className="w-full">
            Settings
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer" onClick={onSupportClick}>
          Support
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem className="cursor-pointer" onClick={onSignOutClick}>
          Sign out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
