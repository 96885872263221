import { callSupabaseRpc } from "@/ajax/utils/callSupabaseFnFromClient";

export type Specialty = {
  id: string;
  code: string;
  grouping: string;
  classification: string;
  name: string;
  is_parent: boolean;
};
export const getSpecialtyCodes = async (
  codes: string[],
): Promise<Specialty[] | null> => {
  const { data, error } = await callSupabaseRpc({
    fnName: "get_medical_specialties",
  }).in("code", codes);

  if (error) {
    throw error;
  }

  return data as Specialty[] | null;
};
