import { updateProviderOnboardingStep } from "@/ajax/onboarding/updateProviderOnboardingStep";
import PhoneForm from "@/components/Forms/PhoneForm";
import { ProviderOnboardingStep } from "../enums/ProviderOnboardingStep";


export default function TextNotificationsOnboarding({
  onComplete,
}: {
  onComplete: () => void;
}) {
  const handleNext = async () => {
    await updateProviderOnboardingStep(ProviderOnboardingStep.TEXT_NOTIFICATIONS);
    onComplete();
  };

  return (
    <PhoneForm onComplete={handleNext} />
  );
}
