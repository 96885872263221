import Select from "react-select";
import { STATES } from "@/consts/states";
import { useMemo, forwardRef } from "react";

interface StateSelectProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export const USStateSelect = forwardRef<any, StateSelectProps>(
  ({ value, onChange, placeholder = "Select state" }, ref) => {
    const stateOptions = useMemo(() => {
      return STATES.map(([name, code]) => ({
        label: name,
        value: code,
      }));
    }, []);

    const selectedOption = useMemo(() => {
      return stateOptions.find((option) => option.value === value);
    }, [value, stateOptions]);

    return (
      <Select
        ref={ref}
        value={selectedOption}
        onChange={(option) => onChange(option?.value || "")}
        options={stateOptions}
        placeholder={placeholder}
        isClearable
        classNames={{
          control: (state) => "py-2",
        }}
      />
    );
  },
);

USStateSelect.displayName = "USStateSelect";
