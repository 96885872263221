import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";
import { Json } from "../../common/types/database.types";
export type FieldValueInputType = {
  field_code: string;
  value: Json;
  index: number;
  delete: boolean | null;
};

export const saveCredentialsFields = async ({
  fieldValues,
  switchUserId,
}: {
  fieldValues: FieldValueInputType[];
  switchUserId?: string | undefined;
}) => {
  return await callSupabaseFnFromClient({
    fnName: "save_user_credentials_fields",
    args: {
      field_values: fieldValues,
      switch_user_id: switchUserId,
    },
  });
};
