import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Bell, Calendar, Settings } from "lucide-react";
import { usePhoneInput } from "react-international-phone";
import { sendPhoneVerification } from "@/ajax/phone/sendPhoneVerification";
import { verifyPhoneCode } from "@/ajax/phone/verifyPhoneCode";
import { updateProviderOnboardingStep } from "@/ajax/onboarding/updateProviderOnboardingStep";
import { ProviderOnboardingStep } from "@/features/onboarding/enums/ProviderOnboardingStep";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@/components/ui/input-otp";
import { validatePhoneNumber } from "@/ajax/phone/validatePhoneNumber";
import Loading from "@/components/Loading";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

type PhoneFormData = {
  phoneNumber: string;
  consent: boolean;
};

type VerificationFormData = {
  verificationCode: string;
};

async function formValidatePhoneNumber(phoneNumber: string) {
  const { valid } = await validatePhoneNumber({
    phoneNumber,
  });
  return valid || "Phone number is not valid";
}

export default function WelcomeStep({
  onComplete,
}: {
  onComplete: () => void;
}) {
  const [showVerification, setShowVerification] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showResendDialog, setShowResendDialog] = useState(false);
  const [hasResent, setHasResent] = useState(false);

  const phoneForm = useForm<PhoneFormData>({
    defaultValues: {
      phoneNumber: "",
      consent: false,
    },
  });

  const verificationForm = useForm<VerificationFormData>({
    reValidateMode: "onChange",
    defaultValues: {
      verificationCode: "",
    },
  });

  const { inputValue, handlePhoneValueChange } = usePhoneInput({
    defaultCountry: "us",
    disableDialCodeAndPrefix: true,
    value: phoneForm.watch("phoneNumber"),
    onChange: ({ phone }) => {
      phoneForm.setValue("phoneNumber", phone, {
        shouldValidate: phoneForm.formState.isSubmitted,
      });
    },
  });

  const onPhoneSubmit = async (data: PhoneFormData) => {
    if (!data.phoneNumber || !data.consent) {
      setError("Please enter your phone number and agree to the terms.");
      return;
    }

    try {
      const { success } = await sendPhoneVerification({
        phoneNumber: data.phoneNumber,
      });

      if (!success) throw new Error();

      setPhoneNumber(data.phoneNumber);
      setShowVerification(true);
      setError("");
    } catch (error) {
      setError("Error sending verification code. Please try again.");
      console.error("Error:", error);
    }
  };

  const onVerificationSubmit = async (data: VerificationFormData) => {
    try {
      const { success } = await verifyPhoneCode({
        phoneNumber: phoneNumber,
        code: data.verificationCode,
      });

      if (!success) throw new Error();

      setError("");
      await handleNext();
    } catch (error) {
      setError("Invalid verification code. Please try again.");
      setShowVerification(false);
      verificationForm.setValue("verificationCode", "");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const verificationCode = verificationForm.watch("verificationCode");
    if (verificationCode.length === 6) {
      verificationForm.handleSubmit(onVerificationSubmit)();
    }
  }, [verificationForm.watch("verificationCode")]);

  const handleNext = async () => {
    onComplete();
  };

  const handleResendCode = async () => {
    if (hasResent) return;

    try {
      const { success } = await sendPhoneVerification({
        phoneNumber: phoneNumber,
      });

      if (!success) throw new Error();

      setHasResent(true);
      setSuccess("Verification code resent successfully.");
      setShowResendDialog(false);
    } catch (error) {
      setError("Error resending verification code. Please try again.");
      console.error("Error:", error);
    }
  };

  const handleGoBack = () => {
    setShowVerification(false);
    setShowResendDialog(false);
    verificationForm.reset();
  };

  phoneForm.register("phoneNumber", {
    required: true,
    validate: {
      checkPhoneNumber: formValidatePhoneNumber,
    },
  });

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center md:justify-center">
      <div className="max-w-3xl w-full bg-white md:rounded-lg md:border min-h-screen md:min-h-min p-4 md:p-8">
        {showVerification ? (
          <div className="animate-fade-in-delay-1">
            <h1 className="text-3xl md:text-3xl font-bold text-gray-900 mb-2 animate-fade-in-delay-1">
              Phone Verification
            </h1>
            <div>Enter the code sent to your phone</div>
            <div className="mb-6">
              We've sent a 6-digit verification code to your phone at:
              <div className="font-medium text-lg text-foreground block mt-1">
                {inputValue}
              </div>
            </div>
            <form
              onSubmit={verificationForm.handleSubmit(onVerificationSubmit)}
              className="space-y-4"
            >
              <div className="flex flex-col justify-center items-center">
                <div>
                  <Controller
                    name="verificationCode"
                    control={verificationForm.control}
                    rules={{
                      required: "Verification code is required",
                      pattern: {
                        value: /^[0-9]{6}$/,
                        message: "Must be a 6-digit number",
                      },
                    }}
                    render={({ field }) => (
                      <InputOTP autoFocus {...field} maxLength={6}>
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    )}
                  />
                </div>
                {verificationForm.formState.errors.verificationCode && (
                  <p className="text-red-500 text-sm mt-1">
                    {verificationForm.formState.errors.verificationCode.message}
                  </p>
                )}
                {error && <p className="text-red-500">{error}</p>}
                {success && <p className="text-green-500">{success}</p>}
              </div>
              <Button
                disabled={verificationForm.formState.isSubmitting}
                size="none"
                type="submit"
                className="w-full py-4"
              >
                {verificationForm.formState.isSubmitting ? (
                  <Loading className="h-6 w-6" />
                ) : (
                  "Verify"
                )}
              </Button>
              <div className="flex items-center justify-center">
                <Button
                  type="button"
                  variant="link"
                  onClick={() => setShowResendDialog(true)}
                >
                  Didn't receive the code?
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className="animate-fade-in-delay-1">
            <h1 className="text-3xl md:text-3xl font-bold text-gray-900 mb-4">
              Enter Phone Number
            </h1>
            <div className="space-y-6">
              <div className="space-y-3">
                <h3 className="font-semibold">
                  Why we need your phone number:
                </h3>
                <div className="flex items-center space-x-2 text-sm">
                  <Bell size={24} />
                  <span>
                    Get notified when your credentials are about to expire
                  </span>
                </div>
                <div className="flex items-center space-x-2 text-sm">
                  <Calendar size={24} />
                  <span>Receive information about your shifts</span>
                </div>
                <div className="flex items-center space-x-2 text-sm ">
                  <Settings size={24} />
                  <span>
                    We never send spam - you can adjust your message settings
                    anytime
                  </span>
                </div>
              </div>
            </div>
            <form
              onSubmit={phoneForm.handleSubmit(onPhoneSubmit)}
              className="space-y-4"
            >
              <div className="mt-8">
                <Label className="mb-2" htmlFor="phoneNumber">
                  Phone Number
                </Label>
                <Controller
                  name="phoneNumber"
                  control={phoneForm.control}
                  rules={{ required: "Phone number is required" }}
                  render={({ field }) => (
                    <Input
                      type="tel"
                      placeholder="(000) 000-0000"
                      value={inputValue}
                      onChange={handlePhoneValueChange}
                      inputMode="tel"
                      autoComplete="tel"
                      ref={field.ref}
                      autoFocus
                    />
                  )}
                />
                {phoneForm.formState.errors.phoneNumber && (
                  <p className="text-red-500 text-sm mt-1">
                    {phoneForm.formState.errors.phoneNumber.message}
                  </p>
                )}
              </div>
              <div className="flex items-start space-x-2">
                <Controller
                  name="consent"
                  control={phoneForm.control}
                  rules={{ required: "You must agree to the terms" }}
                  render={({ field }) => (
                    <Checkbox
                      id="consent"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  )}
                />
                <Label htmlFor="consent" className="text-sm">
                  By providing your phone number and pressing "Save" button
                  below, you agree to receive automated text messages from Saile
                  App Inc. Consent is not a condition for purchase. Message and
                  data rates may apply.
                </Label>
              </div>
              {phoneForm.formState.errors.consent && (
                <p className="text-red-500 text-sm">
                  {phoneForm.formState.errors.consent.message}
                </p>
              )}
              {error && <p className="text-red-500">{error}</p>}

              <div>
                <Button
                  size="none"
                  disabled={phoneForm.formState.isSubmitting}
                  type="submit"
                  className="w-full py-4 mb-4"
                >
                  {phoneForm.formState.isSubmitting ? (
                    <Loading className="h-6 w-6" />
                  ) : (
                    "Save"
                  )}
                </Button>
                <div className="flex items-center justify-center">
                  <Button onClick={handleNext} type="button" variant="link">
                    Skip
                  </Button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      <Dialog open={showResendDialog} onOpenChange={setShowResendDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Didn't receive the code?</DialogTitle>
            <DialogDescription>
              You can choose to resend the code or go back to enter a new phone
              number.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={handleGoBack} variant="outline">
              Go Back
            </Button>
            <Button onClick={handleResendCode} disabled={hasResent}>
              {hasResent ? "Code Resent" : "Resend Code"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
