import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { format, parse } from "date-fns";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { CalendarIcon, X, Info, Upload, AlertCircle } from "lucide-react";
import { Link } from "@tanstack/react-router";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useSuspenseQuery, useMutation } from "@tanstack/react-query";
import { getCredentialsFieldsQueryOptions } from "@/ajax/queries";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import { queryClient } from "@/ajax/queryClient";
import { useState } from "react";
import Loading from "../Loading";
import DatePicker from "react-datepicker";

const formSchema = z.object({
  name: z.object({
    firstName: z.string().min(1).max(255),
    middleName: z.string().max(255).optional(),
    lastName: z.string().min(1).max(255),
    suffixName: z.string().max(255).optional(),
  }),
  otherNames: z
    .array(
      z.object({
        firstName: z.string().min(1).max(255),
        middleName: z.string().max(255).optional(),
        lastName: z.string().min(1).max(255),
        suffixName: z.string().max(255).optional(),
      }),
    )
    .optional(),
  npiNumber: z.string().regex(/^\d{10}$/, "NPI number must be 10 digits"),
  dateOfBirth: z.date(),
});

type FormValues = z.infer<typeof formSchema>;

export function BasicDetailsForm({ onSave }: { onSave: () => void }) {
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { data: basicDetails } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions([
      "name",
      "npi_number",
      "other_names",
      "date_of_birth",
    ]),
  );

  const mutation = useMutation({
    mutationFn: saveCredentialsFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["credentialsFields"] });
      queryClient.invalidateQueries({ queryKey: ["providerUser"] });
      setSaveStatus("idle");
      setErrorMessage(null);
      onSave();
    },
    onError: (error) => {
      setSaveStatus("error");
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to save changes",
      );
      setTimeout(() => setSaveStatus("idle"), 4000);
    },
  });

  const nameValue = basicDetails.name?.values[0].value as {
    first_name: string;
    middle_name: string;
    last_name: string;
    suffix_name: string;
  } | null;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: {
        firstName: (nameValue?.first_name || "") as string,
        middleName: (nameValue?.middle_name || "") as string,
        lastName: (nameValue?.last_name || "") as string,
        suffixName: (nameValue?.suffix_name || "") as string,
      },
      npiNumber: (basicDetails.npi_number?.values[0].value || "") as string,
      dateOfBirth: (basicDetails.date_of_birth?.values[0].value
        ? parse(
            basicDetails.date_of_birth?.values[0].value as string,
            "yyyy-MM-dd",
            new Date(),
          )
        : new Date()) as Date,
      otherNames:
        (
          basicDetails.other_names?.values as {
            value: {
              first_name: string;
              middle_name: string;
              last_name: string;
              suffix_name: string;
            } | null;
          }[]
        )
          .filter((val) => val.value)
          .map(
            (
              val: {
                value: {
                  first_name: string;
                  middle_name: string;
                  last_name: string;
                  suffix_name: string;
                } | null;
              },
              index: number,
            ) => {
              const value = val.value as {
                first_name: string;
                middle_name: string;
                last_name: string;
                suffix_name: string;
              } | null;

              return {
                firstName: value?.first_name || "",
                middleName: value?.middle_name || "",
                lastName: value?.last_name || "",
                suffixName: value?.suffix_name || "",
              };
            },
          ) || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "otherNames",
  });

  function onSubmit(data: FormValues) {
    setSaveStatus("saving");
    setErrorMessage(null);

    // Get existing indices from DB
    const existingIndices =
      basicDetails.other_names?.values
        .filter((val) => val.value)
        .map((_, i) => i + 1) || [];
    // Get indices from form data
    const formIndices = data.otherNames?.map((_, i) => i + 1) || [];
    // Find indices that exist in DB but not in form - these need to be deleted
    const indicesToDelete = existingIndices.filter(
      (i) => !formIndices.includes(i),
    );

    const fieldValues = [
      {
        field_code: "name",
        value: {
          first_name: data.name.firstName,
          middle_name: data.name.middleName,
          last_name: data.name.lastName,
          suffix_name: data.name.suffixName,
        },
        index: 1,
        delete: false,
      },
      {
        field_code: "npi_number",
        value: data.npiNumber || null,
        index: 1,
        delete: false,
      },
      {
        field_code: "date_of_birth",
        value: format(data.dateOfBirth, "yyyy-MM-dd") || null,
        index: 1,
        delete: false,
      },
      ...(data.otherNames?.map((name, index) => ({
        field_code: "other_names",
        value: {
          first_name: name.firstName,
          middle_name: name.middleName,
          last_name: name.lastName,
          suffix_name: name.suffixName,
        },
        index: index + 1,
        delete: false,
      })) || []),
      ...indicesToDelete.map((index) => ({
        field_code: "other_names",
        value: null,
        index,
        delete: true,
      })),
    ];

    mutation.mutate({
      fieldValues,
    });
  }

  const AutofilledIndicator = ({
    source,
  }: {
    source: {
      source_id: string | null;
      source_title: string | null;
    };
  }) => {
    if (!source.source_id) return null;
    return (
      <div className="flex flex-wrap items-center gap-1 text-xs text-muted-foreground">
        <span>Data from </span>
        <Link
          to={`/provider/documents?fileId=${source.source_id}`}
          className="hover:text-foreground underline font-bold"
        >
          {source.source_title}
        </Link>
      </div>
    );
  };

  const hasEmptyRequiredFields = true;

  return (
    <Form {...form}>
      {hasEmptyRequiredFields && (
        <Alert className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2 flex flex-col gap-1 font-lg">
            Some of these fields can be automatically filled by uploading your
            documents.
            <div>
              <Link
                to="/provider/documents"
                className="font-medium text-primary hover:underline"
              >
                Upload documents →
              </Link>
            </div>
          </AlertDescription>
        </Alert>
      )}

      {errorMessage && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2">{errorMessage}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="name.firstName"
          render={({ field }) => (
            <FormItem>
              <div className="flex flex-col gap-0.5 mb-2">
                <FormLabel>First Name</FormLabel>
                <AutofilledIndicator source={basicDetails.name.values[0]} />
              </div>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="name.middleName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Middle Name</FormLabel>
              <AutofilledIndicator source={basicDetails.name.values[0]} />
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="name.lastName"
          render={({ field }) => (
            <FormItem>
              <div className="flex flex-col gap-0.5 mb-2">
                <FormLabel>Last Name</FormLabel>
                <AutofilledIndicator source={basicDetails.name.values[0]} />
              </div>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="name.suffixName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name Suffix (e.g. Jr., Sr., III)</FormLabel>
              <AutofilledIndicator source={basicDetails.name.values[0]} />
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <h3 className="text-lg font-medium">Previous Legal Names</h3>
            <Popover>
              <PopoverTrigger>
                <Info className="h-4 w-4 text-muted-foreground" />
              </PopoverTrigger>
              <PopoverContent className="w-80">
                Names you have used in the past, such as names before marriage
                or legal name changes.
              </PopoverContent>
            </Popover>
          </div>

          {fields.map((field, index) => (
            <div
              key={field.id}
              className="space-y-4 p-4 border rounded-lg relative"
            >
              <h4 className="text-md font-medium mb-4">
                Previous Legal Name {index + 1}
              </h4>
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className="absolute right-2 top-2"
                onClick={() => remove(index)}
              >
                <X className="h-4 w-4" />
              </Button>

              <FormField
                control={form.control}
                name={`otherNames.${index}.firstName`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>First Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`otherNames.${index}.middleName`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Middle Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`otherNames.${index}.lastName`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Last Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`otherNames.${index}.suffixName`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name Suffix (e.g. Jr., Sr., III)</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          ))}

          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() =>
              append({
                firstName: "",
                middleName: "",
                lastName: "",
                suffixName: "",
              })
            }
          >
            Add Another Name
          </Button>
        </div>

        <FormField
          control={form.control}
          name="npiNumber"
          render={({ field }) => (
            <FormItem>
              <div className="flex flex-col gap-0.5 mb-2">
                <FormLabel>NPI Number</FormLabel>
                <AutofilledIndicator
                  source={basicDetails.npi_number.values[0]}
                />
              </div>
              <FormControl>
                <Input maxLength={10} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="dateOfBirth"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Date of Birth</FormLabel>
              <AutofilledIndicator
                source={basicDetails.date_of_birth.values[0]}
              />
              <div className="relative">
                <DatePicker
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  customInput={
                    <Input
                      value={
                        field.value ? format(field.value, "MM-dd-yyyy") : ""
                      }
                      onChange={(e) => {
                        const date = parse(
                          e.target.value,
                          "MM-dd-yyyy",
                          new Date(),
                        );
                        if (date instanceof Date && !isNaN(date.getTime())) {
                          field.onChange(date);
                        }
                      }}
                    />
                  }
                  dateFormat="MM-dd-yyyy"
                  wrapperClassName="w-full"
                />
                <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          type="submit"
          className={cn(
            "w-full py-6",
            saveStatus === "saved" && "bg-green-600 hover:bg-green-700",
            saveStatus === "error" && "bg-red-600 hover:bg-red-700",
          )}
          disabled={saveStatus === "saving"}
        >
          {saveStatus === "saving" ? (
            <Loading className="w-6 h-6" />
          ) : saveStatus === "saved" ? (
            "Saved!"
          ) : saveStatus === "error" ? (
            "Failed to Save"
          ) : (
            "Save"
          )}
        </Button>
      </form>
    </Form>
  );
}
