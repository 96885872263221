import React from "react";
import AccountDetailsOnboardingForm from "./AccountDetailsOnboardingForm";
import { updateProviderOnboardingStep } from "@/ajax/onboarding/updateProviderOnboardingStep";
import { ProviderOnboardingStep } from "../enums/ProviderOnboardingStep";

export default function AccountDetailsOnboardingStep({
  onComplete,
}: {
  onComplete: () => void;
}) {

  return (
    <div>
      <div>
        <h2 className="text-2xl font-bold text-gray-900 mb-1 animate-fade-in">
          Account Details
        </h2>
        <p className="text-gray-600 mb-6 animate-fade-in-delay-2">
          Fill in the following information to get started
        </p>
        <AccountDetailsOnboardingForm onComplete={onComplete} />
      </div>
    </div>
  );
}
