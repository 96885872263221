import { createFileRoute } from "@tanstack/react-router";
import React, { Suspense, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getCredentialsFieldsQueryOptions } from "@/ajax/queries";
import { format, parse } from "date-fns";
import { MedicalSchoolEducationForm } from "@/components/Forms/MedicalSchoolEducationForm";
import { FifthPathwayEducationForm } from "@/components/Forms/FifthPathwayEducationForm";
import { ProfessionalTrainingForm } from "@/components/Forms/ProfessionalTrainingForm";
import { UndergraduateEducationForm } from "@/components/Forms/UndergraduateEducationForm";
import { LoadingScreen } from "@/components/LoadingScreen/LoadingScreen";
import { ProfileNavigation } from "@/features/profile/components/ProfileNavigation";

export const Route = createFileRoute(
  "/_authenticated/provider/profile/education-training/",
)({
  component: EducationTrainingPage,
});

function EducationTrainingPage() {
  const [editingSections, setEditingSections] = useState<Set<string>>(
    new Set(),
  );
  const { data: education } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions([
      "professional_education",
      "fifth_pathway_education",
      "professional_training",
      "undergraduate_education",
    ]),
  );

  const professionalEducation =
    education.professional_education?.values.filter((val) => val.value) || [];
  const fifthPathwayEducation =
    education.fifth_pathway_education?.values.filter((val) => val.value) || [];
  const professionalTraining =
    education.professional_training?.values.filter((val) => val.value) || [];
  const undergraduateEducation =
    education.undergraduate_education?.values.filter((val) => val.value) || [];

  const formatDate = (dateString?: string) => {
    if (!dateString) return "";
    try {
      return format(parse(dateString, "yyyy-MM-dd", new Date()), "MMM yyyy");
    } catch (e) {
      return "";
    }
  };

  const toggleEditSection = (section: string) => {
    setEditingSections((prev) => {
      const next = new Set(prev);
      if (next.has(section)) {
        next.delete(section);
      } else {
        next.add(section);
      }
      return next;
    });
  };

  const isEditing = (section: string) => editingSections.has(section);

  const handleSave = (section: string) => {
    setEditingSections((prev) => {
      const next = new Set(prev);
      next.delete(section);
      return next;
    });
  };

  const renderSourceLink = (sourceId?: string, sourceTitle?: string) => {
    if (!sourceId || !sourceTitle) return null;
    return (
      <div className="text-sm text-gray-500 hover:underline mt-1">
        <a
          href={`/provider/documents/?fileId=${sourceId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Source: {sourceTitle}
        </a>
      </div>
    );
  };

  return (
    <div className="p-2 md:p-8 space-y-4">
      <ProfileNavigation />
      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <CardTitle className="text-2xl font-bold">
            Medical School Education
          </CardTitle>
          <Button
            variant="outline"
            onClick={() => toggleEditSection("professional")}
          >
            {isEditing("professional") ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {isEditing("professional") ? (
            <Suspense fallback={<LoadingScreen />}>
              <MedicalSchoolEducationForm
                onSave={() => handleSave("professional")}
              />
            </Suspense>
          ) : (
            <>
              {professionalEducation.map((edu: any, index: number) => (
                <div key={index} className="mb-4">
                  <div className="flex justify-between items-start">
                    <h4 className="font-semibold">{edu.value.degree}</h4>
                    <div className="text-sm text-gray-500">
                      {formatDate(edu.value.start_date)} -
                      {formatDate(edu.value.end_date)}
                    </div>
                  </div>
                  <div className="text-gray-600">{edu.value.institution}</div>
                  {edu.value.program && (
                    <div className="text-sm text-gray-500">
                      {edu.value.program}
                    </div>
                  )}
                  {edu.value.location && (
                    <div className="text-sm text-gray-500">
                      {edu.value.location}
                    </div>
                  )}
                  {edu.value.specialty && (
                    <div className="text-sm text-gray-500">
                      {edu.value.specialty}
                    </div>
                  )}
                  {renderSourceLink(edu.source_id, edu.source_title)}
                </div>
              ))}
              {professionalEducation.length === 0 && (
                <div className="text-gray-500">
                  No professional education added yet
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>

      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <CardTitle className="text-2xl font-bold break-words">
            Residency/
            <wbr />
            Fellowship/
            <wbr />
            Other
          </CardTitle>
          <Button
            variant="outline"
            onClick={() => toggleEditSection("training")}
          >
            {isEditing("training") ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {isEditing("training") ? (
            <Suspense fallback={<LoadingScreen />}>
              <ProfessionalTrainingForm onSave={() => handleSave("training")} />
            </Suspense>
          ) : (
            <>
              {professionalTraining.map((training: any, index: number) => (
                <div key={index} className="mb-4">
                  <div className="flex justify-between items-start">
                    <h4 className="font-semibold">
                      {training.value.training_type}
                    </h4>
                    <div className="text-sm text-gray-500">
                      {formatDate(training.value.start_date)} -
                      {formatDate(training.value.end_date)}
                    </div>
                  </div>
                  <div className="text-gray-600">
                    {training.value.institution}
                  </div>
                  {training.value.affiliated_university && (
                    <div className="text-sm text-gray-500">
                      Affiliated with {training.value.affiliated_university}
                    </div>
                  )}
                  {training.value.city &&
                    training.value.state &&
                    training.value.country && (
                      <div className="text-sm text-gray-500">
                        {training.value.city}, {training.value.state},{" "}
                        {training.value.country}
                      </div>
                    )}
                  {training.value.degree && (
                    <div className="text-sm text-gray-500">
                      Degree: {training.value.degree}
                    </div>
                  )}
                  {training.value.program && (
                    <div className="text-sm text-gray-500">
                      Program: {training.value.program}
                    </div>
                  )}
                  {training.value.specialty && (
                    <div className="text-sm text-gray-500">
                      Specialty: {training.value.specialty}
                    </div>
                  )}
                  {renderSourceLink(training.source_id, training.source_title)}
                </div>
              ))}
              {professionalTraining.length === 0 && (
                <div className="text-gray-500">
                  No professional training added yet
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>

      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <CardTitle className="text-2xl font-bold">
            Fifth Pathway Education
          </CardTitle>
          <Button
            variant="outline"
            onClick={() => toggleEditSection("fifth_pathway")}
          >
            {isEditing("fifth_pathway") ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {isEditing("fifth_pathway") ? (
            <Suspense fallback={<LoadingScreen />}>
              <FifthPathwayEducationForm
                onSave={() => handleSave("fifth_pathway")}
              />
            </Suspense>
          ) : (
            <>
              {fifthPathwayEducation.map((edu: any, index: number) => (
                <div key={index} className="mb-4">
                  <div className="flex justify-between items-start">
                    <h4 className="font-semibold">{edu.value.degree}</h4>
                    <div className="text-sm text-gray-500">
                      {formatDate(edu.value.start_date)} -
                      {formatDate(edu.value.end_date)}
                    </div>
                  </div>
                  <div className="text-gray-600">{edu.value.institution}</div>
                  {edu.value.program && (
                    <div className="text-sm text-gray-500">
                      {edu.value.program}
                    </div>
                  )}
                  {edu.value.location && (
                    <div className="text-sm text-gray-500">
                      {edu.value.location}
                    </div>
                  )}
                  {edu.value.specialty && (
                    <div className="text-sm text-gray-500">
                      {edu.value.specialty}
                    </div>
                  )}
                  {renderSourceLink(edu.source_id, edu.source_title)}
                </div>
              ))}
              {fifthPathwayEducation.length === 0 && (
                <div className="text-gray-500">
                  No fifth pathway education added yet
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>

      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <CardTitle className="text-2xl font-bold">
            Undergraduate Education
          </CardTitle>
          <Button
            variant="outline"
            onClick={() => toggleEditSection("undergraduate")}
          >
            {isEditing("undergraduate") ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {isEditing("undergraduate") ? (
            <Suspense fallback={<LoadingScreen />}>
              <UndergraduateEducationForm
                onSave={() => handleSave("undergraduate")}
              />
            </Suspense>
          ) : (
            <>
              {undergraduateEducation.map((edu: any, index: number) => (
                <div key={index} className="mb-4">
                  <div className="flex justify-between items-start">
                    <h4 className="font-semibold">{edu.value.degree}</h4>
                    <div className="text-sm text-gray-500">
                      {formatDate(edu.value.start_date)} -
                      {formatDate(edu.value.end_date)}
                    </div>
                  </div>
                  <div className="text-gray-600">{edu.value.institution}</div>
                  {edu.value.program && (
                    <div className="text-sm text-gray-500">
                      {edu.value.program}
                    </div>
                  )}
                  {edu.value.location && (
                    <div className="text-sm text-gray-500">
                      {edu.value.location}
                    </div>
                  )}
                  {edu.value.specialty && (
                    <div className="text-sm text-gray-500">
                      {edu.value.specialty}
                    </div>
                  )}
                  {renderSourceLink(edu.source_id, edu.source_title)}
                </div>
              ))}
              {undergraduateEducation.length === 0 && (
                <div className="text-gray-500">
                  No undergraduate education added yet
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
