import { supabase } from "@/ajax/clients/supabase";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { getEnvVar, isNative } from "@/common/utils/environment";

export const initializeGoogleAuth = async () => {
  if (isNative()) {
    console.log(
      "Initializing Google Auth",
      getEnvVar("VITE_PUBLIC_GOOGLE_AUTH_CLIENT_ID"),
    );

    await GoogleAuth.initialize({
      clientId: getEnvVar("VITE_PUBLIC_GOOGLE_AUTH_IOS_CLIENT_ID"),
      scopes: ["profile", "email"],
      grantOfflineAccess: false,
    });
  }
};

const nativeSignInWithGoogle = async () => {
  try {
    const result = await GoogleAuth.signIn();

    if (!result.authentication) {
      throw new Error("No authentication data present!");
    }

    const { data, error } = await supabase.auth.signInWithIdToken({
      provider: "google",
      token: result.authentication.idToken,
    });

    if (error) throw error;

    return { error, data };
  } catch (error) {
    console.error("Error signing in with Google:", error);
    throw error;
  }
};

/***
 * Sign in with Google email. This redirects the user to Google auth and they are redirected back when done.
 * @returns
 */
const webSignInWithGoogle = async () => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: "google",
  });

  return {
    data,
    error,
  };
};

export const signInWithGoogle = async () => {
  if (isNative()) {
    return nativeSignInWithGoogle();
  }

  return webSignInWithGoogle();
};
