import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { format } from "date-fns";
import { CalendarIcon, X, AlertCircle } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useSuspenseQuery, useMutation } from "@tanstack/react-query";
import { getCredentialsFieldsQueryOptions } from "@/ajax/queries";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import { queryClient } from "@/ajax/queryClient";
import { useState } from "react";
import Loading from "../Loading";
import DatePicker from "react-datepicker";
import { cn } from "@/lib/utils";
import { Checkbox } from "@/components/ui/checkbox";
import { CountrySelect } from "../Selects/CountrySelect";
import { USStateSelect } from "../Selects/USStateSelect";

const workExperienceSchema = z.object({
  start_date: z.date(),
  end_date: z.date().optional(),
  currently_working: z.boolean().default(false),
  position: z.string().min(1, "Position is required").max(1000),
  organization: z.string().min(1, "Organization is required").max(1000),
  country: z.string().min(1, "Country is required").max(100),
  state: z.string().max(100).optional(),
  city: z.string().min(1, "City is required").max(100),
  responsibilities: z.string().max(1000).optional(),
});

const formSchema = z.object({
  workExperience: z.array(workExperienceSchema),
});

type FormValues = z.infer<typeof formSchema>;

export function WorkHistoryForm({ onSave }: { onSave: () => void }) {
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data: workHistory } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions(["work_experience"]),
  );

  const mutation = useMutation({
    mutationFn: saveCredentialsFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["credentialsFields"] });
      setSaveStatus("idle");
      setErrorMessage(null);
      onSave();
    },
    onError: (error) => {
      setSaveStatus("error");
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to save changes",
      );
      setTimeout(() => setSaveStatus("idle"), 4000);
    },
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      workExperience:
        workHistory.work_experience?.values
          .filter((val) => val.value)
          .map((val: { value: any }) => ({
            start_date: val.value?.start_date
              ? new Date(val.value.start_date)
              : new Date(),
            end_date: val.value?.end_date
              ? new Date(val.value.end_date)
              : undefined,
            currently_working: !val.value?.end_date,
            position: val.value?.position || "",
            organization: val.value?.organization || "",
            country: val.value?.country || "United States",
            state: val.value?.state || "",
            city: val.value?.city || "",
            responsibilities: val.value?.responsibilities || "",
          })) || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "workExperience",
  });

  function onSubmit(data: FormValues) {
    setSaveStatus("saving");
    setErrorMessage(null);

    const existingValues = workHistory.work_experience?.values || [];
    const newIndexMax = data.workExperience.length;

    const fieldValues = [
      // Handle existing/updated values
      ...data.workExperience.map((experience, index) => ({
        field_code: "work_experience",
        value: {
          start_date: format(experience.start_date, "yyyy-MM-dd"),
          end_date: experience.currently_working
            ? null
            : experience.end_date
              ? format(experience.end_date, "yyyy-MM-dd")
              : null,
          position: experience.position,
          organization: experience.organization,
          country: experience.country,
          state: experience.state,
          city: experience.city,
          responsibilities: experience.responsibilities,
        },
        delete: false,
        index: index + 1,
      })),

      // Handle deleted values
      ...existingValues
        .filter((val: { index: number }) => val.index > newIndexMax)
        .map((val: { index: number }) => ({
          field_code: "work_experience",
          value: null,
          delete: true,
          index: val.index,
        })),
    ];

    mutation.mutate({
      fieldValues,
    });
  }

  return (
    <Form {...form}>
      {errorMessage && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2">{errorMessage}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-4">
          {fields.map((field, index) => (
            <div
              key={field.id}
              className="space-y-4 p-4 border rounded-lg relative"
            >
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className="absolute right-2 top-2"
                onClick={() => remove(index)}
              >
                <X className="h-4 w-4" />
              </Button>
              <FormField
                control={form.control}
                name={`workExperience.${index}.position`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Position</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`workExperience.${index}.organization`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Organization</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`workExperience.${index}.country`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Country</FormLabel>
                    <FormControl>
                      <CountrySelect {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name={`workExperience.${index}.state`}
                  render={({ field }) => {
                    const countryValue = form.watch(
                      `workExperience.${index}.country`,
                    );
                    return (
                      <FormItem>
                        <FormLabel>State</FormLabel>
                        <FormControl>
                          {countryValue === "United States" ? (
                            <USStateSelect {...field} />
                          ) : (
                            <Input {...field} />
                          )}
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />

                <FormField
                  control={form.control}
                  name={`workExperience.${index}.city`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>City</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name={`workExperience.${index}.responsibilities`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Responsibilities</FormLabel>
                    <FormControl>
                      <Textarea {...field} className="min-h-[100px]" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name={`workExperience.${index}.start_date`}
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>Start Date</FormLabel>
                      <div className="relative">
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          customInput={
                            <Input
                              value={
                                field.value
                                  ? format(field.value, "MM-dd-yyyy")
                                  : ""
                              }
                            />
                          }
                          dateFormat="MM-dd-yyyy"
                          wrapperClassName="w-full"
                        />
                        <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name={`workExperience.${index}.end_date`}
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>End Date</FormLabel>
                      <div className="relative">
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          customInput={
                            <Input
                              value={
                                field.value
                                  ? format(field.value, "MM-dd-yyyy")
                                  : ""
                              }
                              placeholder="Present"
                              disabled={form.watch(
                                `workExperience.${index}.currently_working`,
                              )}
                            />
                          }
                          dateFormat="MM-dd-yyyy"
                          wrapperClassName="w-full"
                          isClearable
                          disabled={form.watch(
                            `workExperience.${index}.currently_working`,
                          )}
                        />
                        <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name={`workExperience.${index}.currently_working`}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked);
                          if (checked) {
                            form.setValue(
                              `workExperience.${index}.end_date`,
                              undefined,
                            );
                          }
                        }}
                      />
                    </FormControl>
                    <FormLabel>I currently work here</FormLabel>
                  </FormItem>
                )}
              />
            </div>
          ))}

          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() =>
              append({
                start_date: new Date(),
                end_date: undefined,
                currently_working: false,
                position: "",
                organization: "",
                country: "United States",
                state: "",
                city: "",
                responsibilities: "",
              })
            }
          >
            Add Work Experience
          </Button>
        </div>

        <Button
          type="submit"
          className={cn(
            "w-full py-6",
            saveStatus === "saved" && "bg-green-600 hover:bg-green-700",
            saveStatus === "error" && "bg-red-600 hover:bg-red-700",
          )}
          disabled={saveStatus === "saving"}
        >
          {saveStatus === "saving" ? (
            <Loading className="w-6 h-6" />
          ) : saveStatus === "saved" ? (
            "Saved!"
          ) : saveStatus === "error" ? (
            "Failed to Save"
          ) : (
            "Save"
          )}
        </Button>
      </form>
    </Form>
  );
}
