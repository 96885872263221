import { Input } from "@/components/ui/input";
import { usePhoneInput } from "react-international-phone";
import { forwardRef } from "react";
import { cn } from "@/lib/utils";

interface PhoneInputProps {
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
}

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ value, onChange, className, ...props }, ref) => {
    const handleChange = ({ phone }: { phone: string }) => {
      onChange?.(phone);
    };

    const { inputValue, handlePhoneValueChange } = usePhoneInput({
      defaultCountry: "us",
      disableDialCodeAndPrefix: true,
      value: value,
      onChange: handleChange,
    });

    return (
      <Input
        type="tel"
        ref={ref}
        value={inputValue}
        onChange={handlePhoneValueChange}
        className={cn("", className)}
        {...props}
      />
    );
  },
);

PhoneInput.displayName = "PhoneInput";

export { PhoneInput };
