import { ProviderUser } from "@/common/types/ProviderUser";
import AccountDetailsOnboardingStep from "../components/AccountDetailsOnboardingStep";
import CredentialUploadStep from "../components/CredentialUploadStep";
import JobPreferencesOnboardingStep from "../components/JobPreferencesOnboardingStep";
import PushNotificationsOnboarding from "../components/PushNotificationsOnboarding";
import { ProviderOnboardingStep } from "../enums/ProviderOnboardingStep";
import { isNative } from "@/common/utils/environment";
import TextNotificationsOnboarding from "../components/TextNotificationsOnboarding";
import { Bell, BriefcaseBusiness, Upload } from "lucide-react";

export type OnboardingStep = {
    title: string;
    id: ProviderOnboardingStep;
    component: React.ComponentType<{ onComplete: () => void; providerUser: ProviderUser }>;
    icon: React.ComponentType<{ className: string }>;
    valid: (user: ProviderUser) => boolean;
}

const ONBOARDING_STEPS: OnboardingStep[] = [
    { 
        title: "Account Details", 
        id: ProviderOnboardingStep.ACCOUNT_DETAILS,
        component: AccountDetailsOnboardingStep, 
        icon: ({ className }: { className: string }) => (
            <svg
              className={className}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 16"
            >
              <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"></path>
            </svg>
          ),
        valid: (user: ProviderUser) => {
            const name = user.credentials.find((credential) => credential.code === "name")?.value as { first_name: string; last_name: string };
            const npi = user.credentials.find((credential) => credential.code === "npi_number")?.value as string;
            return Boolean(name?.first_name && name?.last_name && npi);
        },
    },
    { 
        title: "Job Preferences",
        id: ProviderOnboardingStep.SHIFT_PREFERENCES,
        component: JobPreferencesOnboardingStep, 
        icon: ({ className }: { className: string }) => (
            <BriefcaseBusiness className={className} />
          ),
        valid: (user: ProviderUser) => Boolean(user.onboarding_steps_completed.find((step) => step.step_id === ProviderOnboardingStep.SHIFT_PREFERENCES))
    },
    { 
        title: "Text Notifications", 
        id: ProviderOnboardingStep.TEXT_NOTIFICATIONS,
        component: TextNotificationsOnboarding, 
        icon: ({ className }: { className: string }) => (
            <Bell className={className} />
          ),
        valid: (user: ProviderUser) => Boolean(user.onboarding_steps_completed.find((step) => step.step_id === ProviderOnboardingStep.TEXT_NOTIFICATIONS) || isNative())
    },
    { 
        title: "Push Notifications", 
        id: ProviderOnboardingStep.PUSH_NOTIFICATIONS,
        component: PushNotificationsOnboarding, 
        icon: ({ className }: { className: string }) => (
            <Bell className={className} />
          ),
        valid: (user: ProviderUser) => Boolean(user.onboarding_steps_completed.find((step) => step.step_id === ProviderOnboardingStep.PUSH_NOTIFICATIONS) || !isNative())
    },
    { 
        title: "Upload Documents",
        id: ProviderOnboardingStep.UPLOAD_DOCUMENTS,
        component: CredentialUploadStep,
        
        icon: ({ className }: { className: string }) => (
            <Upload className={className} />
          ),
        valid: (user: ProviderUser) => Boolean(user.onboarding_steps_completed.find((step) => step.step_id === ProviderOnboardingStep.UPLOAD_DOCUMENTS))
    },
  ];

export function getOnboardingSteps(user: ProviderUser): OnboardingStep[] {
    return ONBOARDING_STEPS.filter((step) => !step.valid(user));
}