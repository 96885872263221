import { createFileRoute } from "@tanstack/react-router";
import React, { Suspense, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getCredentialsFieldsQueryOptions } from "@/ajax/queries";
import { format, parse } from "date-fns";
import { StateLicensesForm } from "@/components/Forms/StateLicensesForm";
import { DEAForm } from "@/components/Forms/DEAForm";
import { CDSForm } from "@/components/Forms/CDSForm";
import { ACLSForm } from "@/components/Forms/ACLSForm";
import { LoadingScreen } from "@/components/LoadingScreen/LoadingScreen";
import { ProfileNavigation } from "@/features/profile/components/ProfileNavigation";

export const Route = createFileRoute(
  "/_authenticated/provider/profile/professional-licenses/",
)({
  component: StateLicensesPage,
});

function StateLicensesPage() {
  const [editingSections, setEditingSections] = useState<Set<string>>(
    new Set(),
  );
  const { data: licenses } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions([
      "state_medical_licenses",
      "dea",
      "cds",
      "acls",
    ]),
  );

  const stateLicenses =
    licenses.state_medical_licenses?.values.filter((val) => val.value) || [];
  const deaCertification =
    licenses.dea?.values.filter((val) => val.value) || [];
  const cdsCertification =
    licenses.cds?.values.filter((val) => val.value) || [];
  const aclsCertification =
    licenses.acls?.values.filter((val) => val.value) || [];

  const formatDate = (dateString?: string) => {
    if (!dateString) return "";
    try {
      return format(parse(dateString, "yyyy-MM-dd", new Date()), "MMM yyyy");
    } catch (e) {
      return "";
    }
  };

  const toggleEditSection = (section: string) => {
    setEditingSections((prev) => {
      const next = new Set(prev);
      if (next.has(section)) {
        next.delete(section);
      } else {
        next.add(section);
      }
      return next;
    });
  };

  const isEditing = (section: string) => editingSections.has(section);

  const handleSave = (section: string) => {
    setEditingSections((prev) => {
      const next = new Set(prev);
      next.delete(section);
      return next;
    });
  };

  const renderSourceLink = (sourceId?: string, sourceTitle?: string) => {
    if (!sourceId || !sourceTitle) return null;
    return (
      <div className="text-sm text-gray-500 hover:underline mt-1">
        <a
          href={`/provider/documents/?fileId=${sourceId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Source: {sourceTitle}
        </a>
      </div>
    );
  };

  return (
    <div className="p-2 md:p-8 space-y-4">
      <ProfileNavigation />
      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <CardTitle className="text-2xl font-bold">
            State Medical Licenses
          </CardTitle>
          <Button
            variant="outline"
            onClick={() => toggleEditSection("licenses")}
          >
            {isEditing("licenses") ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {isEditing("licenses") ? (
            <Suspense fallback={<LoadingScreen />}>
              <StateLicensesForm onSave={() => handleSave("licenses")} />
            </Suspense>
          ) : (
            <>
              {stateLicenses.map((license: any, index: number) => (
                <div key={index} className="mb-4">
                  <div className="flex justify-between items-start">
                    <h4 className="font-semibold">{license.value.state}</h4>
                    <div className="text-sm text-gray-500">
                      Expires: {formatDate(license.value.expiration_date)}
                    </div>
                  </div>
                  <div className="text-gray-600">
                    License #: {license.value.license_number}
                  </div>
                  {renderSourceLink(license.source_id, license.source_title)}
                </div>
              ))}
              {stateLicenses.length === 0 && (
                <div className="text-gray-500">No state licenses added yet</div>
              )}
            </>
          )}
        </CardContent>
      </Card>

      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <CardTitle className="text-2xl font-bold">
            DEA Certification
          </CardTitle>
          <Button variant="outline" onClick={() => toggleEditSection("dea")}>
            {isEditing("dea") ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {isEditing("dea") ? (
            <Suspense fallback={<LoadingScreen />}>
              <DEAForm onSave={() => handleSave("dea")} />
            </Suspense>
          ) : (
            <>
              {deaCertification.map((dea: any, index: number) => (
                <div key={index}>
                  <div className="flex justify-between items-start">
                    <h4 className="font-semibold">{dea.value.number}</h4>
                    <div className="text-sm text-gray-500">
                      Expires: {formatDate(dea.value.expiration_date)}
                    </div>
                  </div>
                  {dea.value.business_activity && (
                    <div className="text-sm text-gray-500">
                      Business Activity: {dea.value.business_activity}
                    </div>
                  )}
                  {dea.value.schedules && (
                    <div className="text-sm text-gray-500">
                      Schedules: {dea.value.schedules}
                    </div>
                  )}
                  {renderSourceLink(dea.source_id, dea.source_title)}
                </div>
              ))}
              {deaCertification.length === 0 && (
                <div className="text-gray-500">
                  No DEA certification added yet
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>

      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <CardTitle className="text-2xl font-bold">
            CDS Certification
          </CardTitle>
          <Button variant="outline" onClick={() => toggleEditSection("cds")}>
            {isEditing("cds") ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {isEditing("cds") ? (
            <Suspense fallback={<LoadingScreen />}>
              <CDSForm onSave={() => handleSave("cds")} />
            </Suspense>
          ) : (
            <>
              {cdsCertification.map((cds: any, index: number) => (
                <div key={index}>
                  <div className="flex justify-between items-start">
                    <h4 className="font-semibold">{cds.value.number}</h4>
                    <div className="text-sm text-gray-500">
                      Expires: {formatDate(cds.value.expiration_date)}
                    </div>
                  </div>
                  {cds.value.state_code && (
                    <div className="text-sm text-gray-500">
                      State: {cds.value.state_code}
                    </div>
                  )}
                  {cds.value.details && (
                    <div className="text-sm text-gray-500">
                      Details: {cds.value.details}
                    </div>
                  )}
                  {cds.value.issue_date && (
                    <div className="text-sm text-gray-500">
                      Issued: {formatDate(cds.value.issue_date)}
                    </div>
                  )}
                  {renderSourceLink(cds.source_id, cds.source_title)}
                </div>
              ))}
              {cdsCertification.length === 0 && (
                <div className="text-gray-500">
                  No CDS certification added yet
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>

      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <CardTitle className="text-2xl font-bold">
            ACLS Certification
          </CardTitle>
          <Button variant="outline" onClick={() => toggleEditSection("acls")}>
            {isEditing("acls") ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {isEditing("acls") ? (
            <Suspense fallback={<LoadingScreen />}>
              <ACLSForm onSave={() => handleSave("acls")} />
            </Suspense>
          ) : (
            <>
              {aclsCertification.map((acls: any, index: number) => (
                <div key={index}>
                  <div className="flex justify-between items-start">
                    <h4 className="font-semibold">{acls.value.ecard_code}</h4>
                    <div className="text-sm text-gray-500">
                      Expires: {formatDate(acls.value.expiration_date)}
                    </div>
                  </div>
                  {acls.value.issue_date && (
                    <div className="text-sm text-gray-500">
                      Issued: {formatDate(acls.value.issue_date)}
                    </div>
                  )}
                  {renderSourceLink(acls.source_id, acls.source_title)}
                </div>
              ))}
              {aclsCertification.length === 0 && (
                <div className="text-gray-500">
                  No ACLS certification added yet
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
