import { Link } from "@tanstack/react-router";
import {
  CalendarRangeIcon,
  FolderIcon,
  HouseIcon,
  Menu,
  Settings,
  SquareUserRound,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import React, { useState } from "react";
import { queryClient } from "@/ajax/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import SidebarItem from "@/components/Sidebar/SidebarItem";
import { UserAvatar } from "@/components/UserAvatar/UserAvatar";
import { useRouterState } from "@tanstack/react-router";

const sideItems = [
  {
    icon: <HouseIcon />,
    label: "Home",
    route: "/provider/home",
  },
  {
    icon: <FolderIcon />,
    label: "Documents",
    route: "/provider/documents",
  },
  {
    icon: <CalendarRangeIcon />,
    label: "Shifts",
    route: "/provider/shifts",
  },
  {
    icon: <SquareUserRound />,
    label: "Profile",
    route: "/provider/profile",
    subItems: [
      {
        label: "Personal Information",
        route: "/provider/profile/basic-info",
      },
      {
        label: "Contact Information",
        route: "/provider/profile/contact-info",
      },
      {
        label: "Specialties",
        route: "/provider/profile/specialties",
      },
      {
        label: "Professional Licenses",
        route: "/provider/profile/professional-licenses",
      },
      {
        label: "Work Experience",
        route: "/provider/profile/work-experience",
      },
      {
        label: "Education & Training",
        route: "/provider/profile/education-training",
      },
      {
        label: "References",
        route: "/provider/profile/references",
      },
    ],
  },
  // {
  //   icon: <Megaphone />,
  //   label: "Referrals",
  //   route: "/provider/referrals",
  // },
  {
    icon: <Settings />,
    label: "Settings",
    route: "/provider/settings",
  },
];

const mobileNavItems = [
  {
    icon: <HouseIcon />,
    label: "Home",
    route: "/provider/home",
  },
  {
    icon: <FolderIcon />,
    label: "Documents",
    route: "/provider/documents",
  },
  {
    icon: <CalendarRangeIcon />,
    label: "Shifts",
    route: "/provider/shifts", 
  },
  {
    icon: <SquareUserRound />,
    label: "Profile",
    route: "/provider/profile",
  }
];

export function ProviderLayout({
  children,
  showSidebar = true,
}: {
  children: React.ReactNode;
  showSidebar?: boolean;
}) {
  const [openSheet, setOpenSheet] = useState(false);
  const router = useRouterState();

  return (
    <QueryClientProvider client={queryClient}>
      <div
        className={`min-h-screen w-full ${showSidebar ? "grid md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]" : ""}`}
      >
        {showSidebar && (
          <div className="hidden border-r bg-muted/40 md:block">
            <div className="flex h-full max-h-screen flex-col gap-2">
              <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
                <Link
                  to="/provider/home"
                  className="flex items-center gap-2 font-semibold"
                >
                  <img
                    alt="Saile Logo"
                    src="/static/images/logo.png"
                    className="w-[114px]"
                  />
                </Link>
              </div>
              <div className="flex-1">
                <nav className="grid items-start px-2 mt-6 text-sm font-medium lg:px-4">
                  {sideItems.map((item) => {
                    const isSelected = router.location.pathname.startsWith(
                      item.route,
                    );
                    return (
                      <React.Fragment key={item.label}>
                        <SidebarItem item={item} />
                        {item.subItems && isSelected && (
                          <div className="ml-6 border-l border-muted-foreground/20 pl-2">
                            {item.subItems.map((subItem) => (
                              <SidebarItem
                                key={subItem.label}
                                item={subItem}
                                className="text-xs text-muted-foreground hover:text-primary py-1"
                              />
                            ))}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </nav>
              </div>
            </div>
          </div>
        )}
        <div className={`${showSidebar ? "flex flex-col" : ""}`}>
          <header className="fixed flex top-0 left-0 right-0 md:static md:right-[initial] md:left-[initial] md:top-[initial] md:w-[initial] z-50 h-14 items-center gap-4 border-b bg-muted px-4 h-[60px]">
            {showSidebar && (
              <Sheet open={openSheet} onOpenChange={setOpenSheet}>
                <SheetTrigger asChild>
                  <Button
                    variant="outline"
                    size="icon"
                    className="shrink-0 md:hidden"
                  >
                    <Menu className="h-5 w-5" />
                    <span className="sr-only">Toggle navigation menu</span>
                  </Button>
                </SheetTrigger>
                <SheetContent
                  id="sidebar"
                  side="left"
                  className="flex flex-col"
                >
                  <img
                    alt="Saile Logo"
                    src="/static/images/logo.png"
                    className="ml-2 mb-4 w-[114px]"
                  />
                  <nav className="text-lg font-medium">
                    {sideItems.map((item) => {
                      const isSelected = router.location.pathname.startsWith(
                        item.route,
                      );
                      return (
                        <React.Fragment key={item.label}>
                          <SidebarItem
                            onClick={() => setOpenSheet(false)}
                            item={item}
                          />
                          {item.subItems && isSelected && (
                            <div className="ml-6 border-l border-muted-foreground/20 pl-2">
                              {item.subItems.map((subItem) => (
                                <SidebarItem
                                  key={subItem.label}
                                  item={subItem}
                                  onClick={() => setOpenSheet(false)}
                                  className="text-sm text-muted-foreground hover:text-primary py-1"
                                />
                              ))}
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </nav>
                </SheetContent>
              </Sheet>
            )}
            <div className="w-full flex-1"></div>
            <UserAvatar />
          </header>
          <div id="content" className="bg-gray-100 min-h-screen pb-20 md:pb-10 md:pb-0">
            {children}
          </div>
          {/* Mobile Bottom Navigation */}
          {showSidebar && (
            <div id="mobile-bottom-nav" className="fixed bottom-0 left-0 right-0 border-t bg-white md:hidden">
              <nav className="flex justify-around items-center h-16">
                {mobileNavItems.map((item) => {
                  const isSelected = router.location.pathname.startsWith(item.route);
                  return (
                    <Link
                      key={item.label}
                      to={item.route}
                      className={`flex flex-col items-center justify-center w-full h-full transition-colors ${
                        isSelected 
                          ? "text-primary border-t-2 border-primary" 
                          : "text-muted-foreground hover:text-primary/80 hover:bg-primary/5"
                      }`}
                    >
                      {React.cloneElement(item.icon, { 
                        className: `h-5 w-5 ${isSelected ? "stroke-[2.5px]" : ""}` 
                      })}
                      <span className={`text-xs mt-1 ${isSelected ? "font-medium" : ""}`}>
                        {item.label}
                      </span>
                    </Link>
                  );
                })}
              </nav>
            </div>
          )}
        </div>
      </div>
    </QueryClientProvider>
  );
}
