import { ProviderUser } from "@/common/types/ProviderUser";
import { useSuspenseQuery } from "@tanstack/react-query";
import { providerUserQueryOptions } from "../ajax/queries";

function useProviderUser(): ProviderUser {
  const query = useSuspenseQuery(providerUserQueryOptions());
  return query.data;
}

export { useProviderUser };
