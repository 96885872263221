"use client";
import React, { useState, useEffect } from "react";
import {
  createFileRoute,
  Link,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { FileText, Bell, Briefcase, X } from "lucide-react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Loading from "@/components/Loading";
import { signInWithEmailLink } from "@/ajax/auth/signInWithEmailLink";
import {
  initializeGoogleAuth,
  signInWithGoogle,
} from "@/ajax/auth/signInWithGoogle";
import EmailVerification from "@/components/EmailVerification/EmailVerification";
import { Button } from "@/components/ui/button";

const FormSchema = z.object({
  email: z.string().email("Invalid email address"),
});

type FormValues = z.infer<typeof FormSchema>;

const LOGO_PATH = "/static/images/logo.png";

export const Route = createFileRoute("/auth/signin/")({
  component: SignInPage,

  validateSearch: z.object({
    l: z.string().optional(),
    redirect: z.string().optional(),
  }),
});

function SignInPage() {
  const [serverErrors, setServerErrors] = useState<Record<string, string[]>>(
    {},
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailSent, setEmailSent] = useState<null | string>(null);
  const [showPitch, setShowPitch] = useState(false);
  const navigate = useNavigate();
  const search = Route.useSearch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
  });

  const handleGoogle = async () => {
    const { data, error } = await signInWithGoogle();

    if (error) {
      setServerErrors({
        google: [error.message],
      });
      return;
    }

    if (data && "url" in data && data.url) {
      navigate({ to: data.url });
    } else {
      navigate({ to: "/" });
    }
  };

  const onSubmit = async (data: FormValues) => {
    setIsSubmitting(true);
    setServerErrors({});

    const error = await signInWithEmailLink({
      email: data.email,
      shouldCreateUser: true,
    });
    if (error) {
      setServerErrors({
        _all: [error.message],
      });
      setIsSubmitting(false);
      return;
    }

    setEmailSent(data.email);
  };

  const handleComplete = () => {
    navigate({ to: "/provider/home" });
  };

  const handleBack = () => {
    setEmailSent(null);
    setIsSubmitting(false);
  };

  const handleResend = async () => {
    if (!emailSent) {
      console.error("No email to resend");
      return;
    }

    const error = await signInWithEmailLink({
      email: emailSent,
      shouldCreateUser: true,
    });
  };
  useEffect(() => {
    initializeGoogleAuth();
  }, []);

  if (emailSent) {
    return (
      <EmailVerification
        referralCode={search.l}
        onResend={handleResend}
        onBack={handleBack}
        onComplete={handleComplete}
        email={emailSent}
      />
    );
  }

  const PitchContent = () => (
    <>
      <h1 className="text-4xl font-bold mb-6">Saile Features</h1>
      <div className="space-y-6">
        <div className="flex items-start space-x-3 animate-fade-in-delay-1">
          <div className="flex-shrink-0 mt-1">
            <Briefcase className="w-6 h-6" />
          </div>
          <div>
            <h3 className="font-semibold text-xl">
              Spam-Free Healthcare Shifts
            </h3>
            <div className="mt-1 mb-2">
              <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                Coming Soon
              </span>
            </div>
            <p className="text-gray-100">
              No more recruiter spam - browse high-paying shifts from healthcare
              facilities in your area, on your terms
            </p>
          </div>
        </div>
        <div className="flex items-start space-x-3 animate-fade-in-delay-2">
          <div className="flex-shrink-0 mt-1">
            <FileText className="w-6 h-6" />
          </div>
          <div>
            <h3 className="font-semibold text-xl">Secure Digital Storage</h3>
            <p className="text-gray-100">
              Store all your medical licenses, certifications, and CME credits
              in one secure place
            </p>
          </div>
        </div>
        <div className="flex items-start space-x-3 animate-fade-in-delay-3">
          <div className="flex-shrink-0 mt-1">
            <Bell className="w-6 h-6" />
          </div>
          <div>
            <h3 className="font-semibold text-xl">Never Miss a Deadline</h3>
            <p className="text-gray-100">
              Smart notifications keep you ahead of renewal deadlines. Discounts
              on renewals available.
            </p>
          </div>
        </div>
      </div>
      <img
        src="/static/images/renewal_app_screenshot.png"
        alt="Saile app dashboard"
        className="rounded-lg shadow-lg w-full mt-6"
      />
    </>
  );

  if (showPitch) {
    return (
      <div className="min-h-screen bg-primary text-white p-6">
        <button
          onClick={() => setShowPitch(false)}
          className="mb-6 flex items-center text-white hover:text-gray-200"
        >
          <X className="w-6 h-6 mr-2" /> Back to Sign In
        </button>
        <PitchContent />
      </div>
    );
  }

  return (
    <main className="min-h-screen flex bg-gray-50">
      {/* Left Column - Pitch */}
      <div className="hidden lg:flex w-1/2 bg-primary text-white pt-24 p-12 flex-col justify-between">
        <div>
          <PitchContent />
        </div>
        <div className="space-y-6">
          <div className="bg-primary-dark p-6 rounded-lg">
            <p className="text-sm font-medium">
              Join thousands of healthcare professionals who trust Saile
            </p>
          </div>
        </div>
      </div>

      {/* Right Column - Sign Up Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="max-w-md w-full">
          <div className="text-center mb-8">
            <img
              src={LOGO_PATH}
              alt="Saile Company Logo"
              width={180}
              height={72}
              className="mx-auto mb-8"
            />
            <p className="text-gray-600">
              Sign in with just your email - no password required!
            </p>
            <button
              onClick={() => setShowPitch(true)}
              className="lg:hidden mt-4 text-primary hover:underline text-sm"
            >
              Learn more about Saile
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <Button
              variant="outline"
              size="lg"
              onClick={handleGoogle}
              type="button"
              className="w-full flex items-center justify-center gap-3 border-2 hover:bg-gray-50"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_191_13499)">
                  <path
                    d="M19.999 10.2217C20.0111 9.53428 19.9387 8.84788 19.7834 8.17737H10.2031V11.8884H15.8266C15.7201 12.5391 15.4804 13.162 15.1219 13.7195C14.7634 14.2771 14.2935 14.7578 13.7405 15.1328L13.7209 15.2571L16.7502 17.5568L16.96 17.5774C18.8873 15.8329 19.9986 13.2661 19.9986 10.2217"
                    fill="#4285F4"
                  />
                  <path
                    d="M10.2055 19.9999C12.9605 19.9999 15.2734 19.111 16.9629 17.5777L13.7429 15.1331C12.8813 15.7221 11.7248 16.1333 10.2055 16.1333C8.91513 16.1259 7.65991 15.7205 6.61791 14.9745C5.57592 14.2286 4.80007 13.1801 4.40044 11.9777L4.28085 11.9877L1.13101 14.3765L1.08984 14.4887C1.93817 16.1456 3.24007 17.5386 4.84997 18.5118C6.45987 19.4851 8.31429 20.0004 10.2059 19.9999"
                    fill="#34A853"
                  />
                  <path
                    d="M4.39899 11.9777C4.1758 11.3411 4.06063 10.673 4.05807 9.99996C4.06218 9.32799 4.1731 8.66075 4.38684 8.02225L4.38115 7.88968L1.19269 5.4624L1.0884 5.51101C0.372763 6.90343 0 8.4408 0 9.99987C0 11.5589 0.372763 13.0963 1.0884 14.4887L4.39899 11.9777Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M10.2059 3.86663C11.668 3.84438 13.0822 4.37803 14.1515 5.35558L17.0313 2.59996C15.1843 0.901848 12.7383 -0.0298855 10.2059 -3.6784e-05C8.31431 -0.000477834 6.4599 0.514732 4.85001 1.48798C3.24011 2.46124 1.9382 3.85416 1.08984 5.51101L4.38946 8.02225C4.79303 6.82005 5.57145 5.77231 6.61498 5.02675C7.65851 4.28118 8.9145 3.87541 10.2059 3.86663Z"
                    fill="#EB4335"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_191_13499">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Sign in with Google
            </Button>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t"></span>
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-white px-2 text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="space-y-2">
              <label className="block font-medium text-gray-700">Email</label>
              <div className="relative">
                <input
                  type="email"
                  {...register("email")}
                  placeholder="Enter your email"
                  className="w-full rounded-lg border border-gray-300 px-4 py-3 text-black outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                />
                <span className="absolute right-4 top-3.5 text-gray-400">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path d="M19.2516 3.30005H2.75156C1.58281 3.30005 0.585938 4.26255 0.585938 5.46567V16.6032C0.585938 17.7719 1.54844 18.7688 2.75156 18.7688H19.2516C20.4203 18.7688 21.4172 17.8063 21.4172 16.6032V5.4313C21.4172 4.26255 20.4203 3.30005 19.2516 3.30005ZM19.2516 4.84692C19.2859 4.84692 19.3203 4.84692 19.3547 4.84692L11.0016 10.2094L2.64844 4.84692C2.68281 4.84692 2.71719 4.84692 2.75156 4.84692H19.2516ZM19.2516 17.1532H2.75156C2.40781 17.1532 2.13281 16.8782 2.13281 16.5344V6.35942L10.1766 11.5157C10.4172 11.6875 10.6922 11.7563 10.9672 11.7563C11.2422 11.7563 11.5172 11.6875 11.7578 11.5157L19.8016 6.35942V16.5688C19.8703 16.9125 19.5953 17.1532 19.2516 17.1532Z" />
                    </g>
                  </svg>
                </span>
              </div>
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email.message}</p>
              )}
            </div>

            {serverErrors._all && (
              <p className="text-red-500 text-sm">{serverErrors._all[0]}</p>
            )}

            <Button type="submit" size="lg" className="w-full">
              {isSubmitting ? <Loading className="h-5 w-5" /> : "Sign In"}
            </Button>
          </form>
        </div>
      </div>
    </main>
  );
}
