import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { format } from "date-fns";
import { CalendarIcon, X, AlertCircle } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useSuspenseQuery, useMutation } from "@tanstack/react-query";
import { getCredentialsFieldsQueryOptions } from "@/ajax/queries";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import { queryClient } from "@/ajax/queryClient";
import { useState } from "react";
import Loading from "../Loading";
import DatePicker from "react-datepicker";
import { cn } from "@/lib/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const admittingPrivilegesSchema = z.object({
  hospital_name: z.string().min(1, "Hospital name is required").max(255),
  country: z.string().min(1, "Country is required").max(255),
  state: z.string().min(1, "State is required").max(2),
  privilege_status: z.enum(["Active", "Pending", "Suspended", "Terminated"]),
  privilege_type: z.string().max(255).optional(),
  department: z.string().min(1, "Department is required").max(255),
  start_date: z.date(),
});

const formSchema = z.object({
  admittingPrivileges: z.array(admittingPrivilegesSchema),
});

type FormValues = z.infer<typeof formSchema>;

export function AdmittingPrivilegesForm({ onSave }: { onSave: () => void }) {
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data: privileges } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions(["admitting_privileges"]),
  );

  const mutation = useMutation({
    mutationFn: saveCredentialsFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["credentialsFields"] });
      setSaveStatus("idle");
      setErrorMessage(null);
      onSave();
    },
    onError: (error) => {
      setSaveStatus("error");
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to save changes",
      );
      setTimeout(() => setSaveStatus("idle"), 4000);
    },
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      admittingPrivileges:
        privileges.admitting_privileges?.values
          .filter((val) => val.value)
          .map((val: { value: any }) => ({
            hospital_name: val.value?.hospital_name || "",
            country: val.value?.country || "",
            state: val.value?.state || "",
            privilege_status: val.value?.privilege_status || "Active",
            privilege_type: val.value?.privilege_type || "",
            department: val.value?.department || "",
            start_date: val.value?.start_date
              ? new Date(val.value.start_date)
              : new Date(),
          })) || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "admittingPrivileges",
  });

  function onSubmit(data: FormValues) {
    setSaveStatus("saving");
    setErrorMessage(null);

    const existingIndices =
      privileges.admitting_privileges?.values
        .filter((val) => val.value)
        .map((_, i) => i + 1) || [];
    const formIndices = data.admittingPrivileges?.map((_, i) => i + 1) || [];
    const indicesToDelete = existingIndices.filter(
      (i) => !formIndices.includes(i),
    );

    const fieldValues = [
      ...data.admittingPrivileges.map((privilege, index) => ({
        field_code: "admitting_privileges",
        value: {
          hospital_name: privilege.hospital_name,
          country: privilege.country,
          state: privilege.state,
          privilege_status: privilege.privilege_status,
          privilege_type: privilege.privilege_type,
          department: privilege.department,
          start_date: format(privilege.start_date, "yyyy-MM-dd"),
        },
        index: index + 1,
        delete: false,
      })),
      ...indicesToDelete.map((index) => ({
        field_code: "admitting_privileges",
        value: null,
        index,
        delete: true,
      })),
    ];

    mutation.mutate({
      fieldValues,
    });
  }

  return (
    <Form {...form}>
      {errorMessage && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2">{errorMessage}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-4">
          {fields.map((field, index) => (
            <div
              key={field.id}
              className="space-y-4 p-4 border rounded-lg relative"
            >
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className="absolute right-2 top-2"
                onClick={() => remove(index)}
              >
                <X className="h-4 w-4" />
              </Button>

              <FormField
                control={form.control}
                name={`admittingPrivileges.${index}.hospital_name`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Hospital Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="grid grid-cols-2 gap-4">
                <FormField
                  control={form.control}
                  name={`admittingPrivileges.${index}.country`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Country</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name={`admittingPrivileges.${index}.state`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>State</FormLabel>
                      <FormControl>
                        <Input {...field} maxLength={2} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name={`admittingPrivileges.${index}.privilege_status`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Privilege Status</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select status" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="Active">Active</SelectItem>
                        <SelectItem value="Pending">Pending</SelectItem>
                        <SelectItem value="Suspended">Suspended</SelectItem>
                        <SelectItem value="Terminated">Terminated</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`admittingPrivileges.${index}.privilege_type`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Privilege Type</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`admittingPrivileges.${index}.department`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Department</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`admittingPrivileges.${index}.start_date`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Start Date</FormLabel>
                    <div className="relative">
                      <DatePicker
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        customInput={
                          <Input
                            value={
                              field.value
                                ? format(field.value, "MM-dd-yyyy")
                                : ""
                            }
                          />
                        }
                        dateFormat="MM-dd-yyyy"
                        wrapperClassName="w-full"
                      />
                      <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          ))}

          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() =>
              append({
                hospital_name: "",
                country: "",
                state: "",
                privilege_status: "Active",
                privilege_type: "",
                department: "",
                start_date: new Date(),
              })
            }
          >
            Add Admitting Privilege
          </Button>
        </div>

        <Button
          type="submit"
          className={cn(
            "w-full py-6",
            saveStatus === "saved" && "bg-green-600 hover:bg-green-700",
            saveStatus === "error" && "bg-red-600 hover:bg-red-700",
          )}
          disabled={saveStatus === "saving"}
        >
          {saveStatus === "saving" ? (
            <Loading className="w-6 h-6" />
          ) : saveStatus === "saved" ? (
            "Saved!"
          ) : saveStatus === "error" ? (
            "Failed to Save"
          ) : (
            "Save"
          )}
        </Button>
      </form>
    </Form>
  );
}
