import { createFileRoute } from "@tanstack/react-router";
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { WorkHistoryForm } from "@/components/Forms/WorkExperienceForm";
import { AdmittingPrivilegesForm } from "@/components/Forms/AdmittingPrivilegesForm";
import { Button } from "@/components/ui/button";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getCredentialsFieldsQueryOptions } from "@/ajax/queries";
import { format } from "date-fns";
import { ProfileNavigation } from "@/features/profile/components/ProfileNavigation";

export const Route = createFileRoute(
  "/_authenticated/provider/profile/work-experience/",
)({
  component: WorkExperiencePage,
});

function WorkExperiencePage() {
  const [showWorkForm, setShowWorkForm] = useState(false);
  const [showPrivilegesForm, setShowPrivilegesForm] = useState(false);
  const {
    data: { work_experience, admitting_privileges },
  } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions([
      "work_experience",
      "admitting_privileges",
    ]),
  );

  const workExperiences =
    work_experience?.values.filter((val) => val.value) || [];
  const admittingPrivileges =
    admitting_privileges?.values.filter((val) => val.value) || [];

  return (
    <div className="min-h-screen bg-gray-100 p-2 md:p-8 space-y-4">
      <ProfileNavigation />
      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <div>
            <CardTitle className="text-2xl font-bold">
              Work Experience
            </CardTitle>
            <CardDescription>
              View and manage your work experience information
            </CardDescription>
          </div>
          <Button
            variant="outline"
            onClick={() => setShowWorkForm(!showWorkForm)}
          >
            {showWorkForm ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {showWorkForm ? (
            <WorkHistoryForm onSave={() => setShowWorkForm(false)} />
          ) : (
            <div className="space-y-6">
              {workExperiences.map((exp: any, index: number) => (
                <div key={index}>
                  <div className="flex justify-between items-start">
                    <h3 className="font-semibold text-lg">
                      {exp.value.position}
                    </h3>
                    <div className="text-sm text-gray-500">
                      {format(new Date(exp.value.start_date), "MMM yyyy")} -
                      {exp.value.end_date
                        ? format(new Date(exp.value.end_date), " MMM yyyy")
                        : " Present"}
                    </div>
                  </div>
                  <div className="text-gray-600">{exp.value.organization}</div>
                  <div className="text-sm text-gray-500 mb-2">
                    {exp.value.location}
                  </div>
                  <p className="text-sm text-gray-600 whitespace-pre-line">
                    {exp.value.responsibilities}
                  </p>
                </div>
              ))}
              {workExperiences.length === 0 && (
                <div className="text-center text-gray-500">
                  No work experience added yet. Click Edit to add your work
                  history.
                </div>
              )}
            </div>
          )}
        </CardContent>
      </Card>

      <Card className="max-w-2xl mx-auto">
        <CardHeader className="flex flex-row justify-between items-center">
          <div>
            <CardTitle className="text-2xl font-bold">
              Admitting Privileges
            </CardTitle>
            <CardDescription>
              View and manage your hospital admitting privileges
            </CardDescription>
          </div>
          <Button
            variant="outline"
            onClick={() => setShowPrivilegesForm(!showPrivilegesForm)}
          >
            {showPrivilegesForm ? "Cancel Edit" : "Edit"}
          </Button>
        </CardHeader>
        <CardContent>
          {showPrivilegesForm ? (
            <AdmittingPrivilegesForm
              onSave={() => setShowPrivilegesForm(false)}
            />
          ) : (
            <div className="space-y-6">
              {admittingPrivileges.map((priv: any, index: number) => (
                <div key={index}>
                  <div className="flex justify-between items-start">
                    <h3 className="font-semibold text-lg">
                      {priv.value.hospital_name}
                    </h3>
                    <div className="text-sm text-gray-500">
                      Since{" "}
                      {format(new Date(priv.value.start_date), "MMM yyyy")}
                    </div>
                  </div>
                  <div className="text-gray-600">{priv.value.department}</div>
                  <div className="text-sm text-gray-500 mb-2">
                    {priv.value.country}, {priv.value.state}
                  </div>
                  <div className="text-sm">
                    <span
                      className={`font-medium ${
                        priv.value.privilege_status === "Active"
                          ? "text-green-600"
                          : priv.value.privilege_status === "Pending"
                            ? "text-yellow-600"
                            : "text-red-600"
                      }`}
                    >
                      {priv.value.privilege_status}
                    </span>
                    {priv.value.privilege_type &&
                      ` - ${priv.value.privilege_type}`}
                  </div>
                </div>
              ))}
              {admittingPrivileges.length === 0 && (
                <div className="text-center text-gray-500">
                  No admitting privileges added yet. Click Edit to add
                  privileges.
                </div>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
