import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Pencil } from "lucide-react";
import { PhoneInput } from "@/components/Inputs/PhoneInput";
import { useProviderUser } from "@/hooks/useProviderUser";
import { ProfileNavigation } from "@/features/profile/components/ProfileNavigation";

export const Route = createFileRoute(
  "/_authenticated/provider/profile/contact-info/",
)({
  component: ContactInformationPage,
});

function ContactInformationPage() {
  const providerUser = useProviderUser();
  const [editingField, setEditingField] = useState<"email" | "phone" | null>(
    null,
  );
  const [email, setEmail] = useState(providerUser.email || "");
  const [phone, setPhone] = useState(providerUser.phone || "");
  const [tempValue, setTempValue] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const handleEdit = (field: "email" | "phone") => {
    setEditingField(field);
    setTempValue(field === "email" ? email : phone);
  };

  const handleSave = () => {
    if (tempValue !== (editingField === "email" ? email : phone)) {
      setIsVerifying(true);
    } else {
      setEditingField(null);
    }
  };

  const handleCancel = () => {
    setEditingField(null);
    setTempValue("");
  };

  const handleVerification = async () => {
    // TODO: Add verification API call here
    if (editingField === "email") {
      setEmail(tempValue);
    } else if (editingField === "phone") {
      setPhone(tempValue);
    }
    setIsVerifying(false);
    setVerificationCode("");
    setEditingField(null);
    setTempValue("");
  };

  return (
    <div className="p-2 md:p-8 space-y-4">
      <ProfileNavigation />
      <Card className="max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">
            Contact Information
          </CardTitle>
          <CardDescription>Manage your contact information</CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <div>
              <Label htmlFor="email">Email Address</Label>
              <div className="flex items-center gap-2">
                {editingField === "email" ? (
                  <div className="flex-1">
                    <Input
                      id="email"
                      type="email"
                      value={tempValue}
                      onChange={(e) => setTempValue(e.target.value)}
                    />
                  </div>
                ) : (
                  <div className="flex-1">{email || "No email set"}</div>
                )}
                {editingField === "email" ? (
                  <div className="space-x-2">
                    <Button size="sm" variant="outline" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button size="sm" onClick={handleSave}>
                      Save
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="outline"
                    onClick={() => handleEdit("email")}
                    disabled={editingField !== null}
                  >
                    Edit
                  </Button>
                )}
              </div>
            </div>
            <div>
              <Label htmlFor="phone">Phone Number</Label>
              <div className="flex items-center gap-2">
                {editingField === "phone" ? (
                  <div className="flex-1">
                    <PhoneInput
                      value={tempValue}
                      onChange={(value) => setTempValue(value)}
                    />
                  </div>
                ) : (
                  <div className="flex-1">{phone || "No phone number set"}</div>
                )}
                {editingField === "phone" ? (
                  <div className="space-x-2">
                    <Button size="sm" variant="outline" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button size="sm" onClick={handleSave}>
                      Save
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="outline"
                    onClick={() => handleEdit("phone")}
                    disabled={editingField !== null}
                  >
                    Edit
                  </Button>
                )}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      <Dialog open={isVerifying} onOpenChange={setIsVerifying}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Verify Your {editingField === "email" ? "Email" : "Phone"}
            </DialogTitle>
            <DialogDescription>
              Please enter the verification code sent to your{" "}
              {editingField === "email" ? "email" : "phone"}
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4">
            <Input
              type="text"
              placeholder="Enter verification code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <Button onClick={handleVerification} className="w-full">
              Verify
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
