import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { AlertCircle } from "lucide-react";
import { Link } from "@tanstack/react-router";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useSuspenseQuery, useMutation } from "@tanstack/react-query";
import { getCredentialsFieldsQueryOptions } from "@/ajax/queries";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import { queryClient } from "@/ajax/queryClient";
import { useState } from "react";
import Loading from "../Loading";
import { cn } from "@/lib/utils";
import { SaveButton } from "../Button/SaveButton";

const formSchema = z.object({
  address_1: z.string().min(1).max(255),
  address_2: z.string().max(255).optional(),
  city: z.string().min(1).max(255),
  state: z.string().length(2),
  zip_code: z.string().length(5),
});

type FormValues = z.infer<typeof formSchema>;

export function AddressForm({ onSave }: { onSave: () => void }) {
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { data: basicDetails } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions(["address"]),
  );

  const mutation = useMutation({
    mutationFn: saveCredentialsFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["credentialsFields"] });
      setSaveStatus("idle");
      setErrorMessage(null);
      onSave();
    },
    onError: (error) => {
      setSaveStatus("error");
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to save changes",
      );
      setTimeout(() => setSaveStatus("idle"), 4000);
    },
  });

  const addressValue = basicDetails.address?.values[0].value as {
    address_1: string;
    address_2: string | undefined;
    city: string;
    state: string;
    zip_code: string;
  } | null;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      address_1: addressValue?.address_1 || "",
      address_2: (addressValue?.address_2 || "") as string,
      city: addressValue?.city || "",
      state: addressValue?.state || "",
      zip_code: addressValue?.zip_code || "",
    },
  });

  function onSubmit(data: FormValues) {
    setSaveStatus("saving");
    setErrorMessage(null);

    const fieldValues = [
      {
        field_code: "address",
        value: {
          address_1: data.address_1,
          address_2: data.address_2,
          city: data.city,
          state: data.state,
          zip_code: data.zip_code,
        },
        index: 1,
        delete: false,
      },
    ];

    mutation.mutate({
      fieldValues,
    });
  }

  const AutofilledIndicator = ({
    source,
  }: {
    source: {
      source_id: string | null;
      source_title: string | null;
    };
  }) => {
    if (!source.source_id) return null;
    return (
      <div className="flex flex-wrap items-center gap-1 text-xs text-muted-foreground">
        <span>Data from </span>
        <Link
          to={`/provider/documents?fileId=${source.source_id}`}
          className="hover:text-foreground underline font-bold"
        >
          {source.source_title}
        </Link>
      </div>
    );
  };

  const hasEmptyRequiredFields = true;

  return (
    <Form {...form}>
      {hasEmptyRequiredFields && (
        <Alert className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2 flex flex-col gap-1 font-lg">
            Some of these fields can be automatically filled by uploading your
            documents.
            <div>
              <Link
                to="/provider/documents"
                className="font-medium text-primary hover:underline"
              >
                Upload documents →
              </Link>
            </div>
          </AlertDescription>
        </Alert>
      )}

      {errorMessage && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2">{errorMessage}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="address_1"
          render={({ field }) => (
            <FormItem>
              <div className="flex flex-col gap-0.5 mb-2">
                <FormLabel>Address Line 1</FormLabel>
                <AutofilledIndicator source={basicDetails.address?.values[0]} />
              </div>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="address_2"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Address Line 2</FormLabel>
              <AutofilledIndicator source={basicDetails.address?.values[0]} />
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="city"
          render={({ field }) => (
            <FormItem>
              <div className="flex flex-col gap-0.5 mb-2">
                <FormLabel>City</FormLabel>
                <AutofilledIndicator source={basicDetails.address?.values[0]} />
              </div>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="state"
          render={({ field }) => (
            <FormItem>
              <FormLabel>State</FormLabel>
              <AutofilledIndicator source={basicDetails.address?.values[0]} />
              <FormControl>
                <Input maxLength={2} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="zip_code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>ZIP Code</FormLabel>
              <AutofilledIndicator source={basicDetails.address?.values[0]} />
              <FormControl>
                <Input maxLength={5} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <SaveButton saveStatus={saveStatus} />
      </form>
    </Form>
  );
}
